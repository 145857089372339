import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
export default function UserProfileMenu() {
  const { user, message, error } = useSelector((state) => state.user);

  return (
    <>
      <div className="relative my-4 w-56 sm:hidden">
        <input
          className="peer hidden"
          type="checkbox"
          name="select-1"
          id="select-1"
        />
        <label
          for="select-1"
          className="flex w-full cursor-pointer select-none rounded-lg border p-2 px-3 text-sm text-gray-700 ring-blue-700 peer-checked:ring"
        >
          Accounts{" "}
        </label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="pointer-events-none absolute right-0 top-3 ml-auto mr-5 h-4 text-slate-700 transition peer-checked:rotate-180"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
        <ul className="max-h-0 select-none flex-col overflow-hidden rounded-b-lg shadow-md transition-all duration-300 peer-checked:max-h-56 peer-checked:py-3">
          <Link to="/user/account">
            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
              Accounts
            </li>
          </Link>
          <Link to="/user/profile/detail">
            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
              Profile
            </li>
          </Link>
          <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
            Billing
          </li>
          <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
            Notifications
          </li>
          {user?.role == "buyer" ? (
            <Link to="/buyer/add/company">
              <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
                Become a seller
              </li>
            </Link>
          ) : (
            ""
          )}

          <Link to="/orders">
            <li className="cursor-pointer px-3 py-2 text-sm text-slate-600 hover:bg-blue-700 hover:text-white">
              Orders
            </li>
          </Link>
          {user?.role == "buyer" ? (
            ""
          ) : (
            <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
              Business Profilr
            </li>
          )}
        </ul>
      </div>

      <div className="col-span-2 hidden sm:block">
        <ul>
          <Link to="/user/account">
            <li className="mt-5 cursor-pointer border-l-2 border-l-blue-700 px-2 py-2 font-semibold text-blue-700 transition hover:border-l-blue-700 hover:text-blue-700">
              Accounts
            </li>
          </Link>
          <Link to="/user/profile/detail">
            <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
              Profile
            </li>
          </Link>
          <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
            Billing
          </li>
          <Link to="/orders">
            <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
              Orders
            </li>
          </Link>
          {user?.role == "buyer" ? (
            <Link to="/buyer/add/company">
              <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
                Become a seller
              </li>
            </Link>
          ) : (
            ""
          )}
          <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
            Notifications
          </li>
          {user?.role == "buyer" ? (
            ""
          ) : (
            <Link to="/seller/dashboard">
              <li className="mt-5 cursor-pointer border-l-2 border-transparent px-2 py-2 font-semibold transition hover:border-l-blue-700 hover:text-blue-700">
                Business Profile
              </li>
            </Link>
          )}
        </ul>
      </div>
    </>
  );
}
