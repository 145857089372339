"use client";

import { useState, useEffect } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../BaseFiles/Loader";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import Spinner from "../../BaseFiles/Spinner";
import {
  companyRegistration,
  clearErrors,
  clearMessage,
  updateCompany,
} from "../../redux/companySlice";

export default function EditModal({ open, setOpen, companyData }) {
  const { user } = useSelector((state) => state.user);
  const { loading, message, error } = useSelector((state) => state.company);
  const dispatch = useDispatch();

  const initialValues = {
    seller_id: user.user_id,
    company_name: companyData?.company_name || "",
    gst: companyData?.gst || "",
    category: companyData?.category || "",
    file: null,
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    category: Yup.string().required("Category is required"),
    file: Yup.mixed()
      .test(
        "fileSize",
        "File too large",
        (value) => value && value.size <= 2 * 1024 * 1024
      )
      .test(
        "fileType",
        "Unsupported file format",
        (value) =>
          value &&
          ["image/jpeg", "image/png", "application/pdf"].includes(value.type)
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      // Remove null or undefined values
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v != null)
      );

      dispatch(updateCompany({ company_id: companyData.company_id, updatedData: filteredValues }));
      console.log(filteredValues);
    },
  });

  const handleFileChange = (event) => {
    formik.setFieldValue("file", event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (companyData) {
      formik.setValues({
        seller_id: user.user_id,
        company_name: companyData.company_name,
        gst: companyData.gst,
        email: companyData.email,
        phone: companyData.phone,
        category: companyData.category,
        file: null,
      });
    }
  }, [companyData]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
        formik.resetForm();
        setOpen(false);
      }, 3000);

      return () => clearInterval(messageInterval);
    }
  }, [error, dispatch, message, setOpen]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6"
          >
            <div className="w-full">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Edit Company
                </DialogTitle>
                <div className="mt-2">
                  {message && <SuccessAlert message={message} />}
                  {error && <ErrorAlert error={error} />}
                  <form onSubmit={formik.handleSubmit} className="space-y-4">
                    <div className="flex gap-5">
                      <div className="w-full md:w-1/2">
                        <label
                          htmlFor="company_name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Company Name
                        </label>
                        <input
                          id="company_name"
                          type="text"
                          value={formik.values.company_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>

                      <div className="w-full md:w-1/2">
                        <label
                          htmlFor="gst"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          GST Number
                        </label>
                        <input
                          id="gst"
                          type="text"
                          value={formik.values.gst}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="category"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Category
                      </label>
                      <input
                        id="category"
                        type="text"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>


                    <div>
                      <label
                        htmlFor="file"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Company Logo
                      </label>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        hidden
                        accept="file/png, file/jpeg"
                        onChange={handleFileChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : "Save"}
                      </button>
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
