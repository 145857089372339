import Navbar from "../comman/Navbar"
import HeroSection from "./HeroSection"
import HomeLogo from "./HomeLogo"
import HomePageProduct from "./HomeProduct"
import Footer from "../comman/Footer"



export default function Home(){
    return(
        <>
            <HeroSection/>
            {/* <TempProCard/>
            <TempProCard1/>
            <TempProCard2/>
            <TempProCard3/> */}
            {/* <HomePageProduct title="New Arrival" subtitle="Experience innovation with just a click. Shop our newest arrivals now and stay ahead!"/> */}
            {/* <HomePageProduct title="Suggestion For You" subtitle="Check out personalized recommendations based on your preferences."/> */}
            {/* <HomePageProduct title="DIscount For You" subtitle="Enjoy exclusive savings on selected items that we think you'll love. Check out these special offers just for you!"/> */}
            <HomePageProduct title="Best in Sell" subtitle="Understand the top-selling products that are currently trending. Find out why these items are so popular with our customers."/>
            <HomeLogo/>
        </>
    )
}