import React from "react";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark, FaEye } from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Confirmation } from "../../BaseFiles/Confirmation";
import {
  clearErrors,
  clearMessage,
  getProduct,
  deleteProduct,
  updateProduct,
} from "../../redux/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare } from "react-icons/bs";
import { FaRegTrashAlt, FaCheck, FaSearch } from "react-icons/fa";
import { getSingleSellerProduct } from "../../redux/productSlice"; 
import MessageBox from "../../BaseFiles/MessageBox";
const SellerProducts = (props) => {
  const currentUrl = window.location.href;
  const [editMode, setEditMode] = useState(false);
  const [editableProducts, seteditableProducts] = useState(null);
  const [modalopen, setModalopen] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const { loading, error, message, singlesellerproduct } = useSelector(
    (state) => state.product
  );

  const { user } = useSelector((state) => state.user);
  const [allproduct, setAllproduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(false);

  function isClose() {
    setModalopen(false);
  }
  function handleDelete(id) {
    setdeleteID(id);
    if (deleteID) {
      console.log(id);
      setModalopen(true);
    }
  }
  let addProductValues = {
    title: "",
    description: "",
    category: "",
    colors: "",
    sizes: "",
    price: "",
    stock: "",
    reutrn: "",
  };

  useEffect(() => {
    // setAllproduct(product);
    dispatch(getSingleSellerProduct(user.user_id));
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const handleEdit = (pro) => {
    seteditableProducts(pro);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    seteditableProducts(null);
  };

  const formik = useFormik({
    initialValues: addProductValues,
    onSubmit: (values) => {
      const filteredData = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

      if (Object.keys(filteredData).length > 0) {
        dispatch(
          updateProduct({
            product_id: editableProducts.product_id,
            updatedData: filteredData,
          })
        );
      } else {
        alert("No Changes were made");
      }

      setEditMode(false);
      seteditableProducts(null);
    },
  });

  // const handleSearch = (e) => {
  //   setAllproduct(product?.filter((p) => p.title?.includes(e.target.value)));
  //   setSearchQuery(e.target.value);
  // };
  return (
    <>
      <section className="py-1  w-full m-auto">
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        <div
          className={`flex bg-white justify-center ${
            loading ? "h-[560px] items-center" : "h-full"
          }`}
        >
          {loading ? (
            <Loader />
          ) : (
           
            <div className="px-4 sm:px-6 lg:px-8 w-full">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Products
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the Products in your account including their
                    name, title, category and other detail.
                  </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <Link to="/seller/uploadproduct"
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add Product
                  </Link>
                </div>
              </div>
              <div className="mt-8 flow-root w-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                          >
                            image
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            colors
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            stock
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            category
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            price
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            reutrn
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                          >
                            <span className="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {singlesellerproduct && singlesellerproduct?.length === 0? <MessageBox message={'No products found yet'}/>  : singlesellerproduct?.map((pro) => (
                          <tr key={pro?.product_id}>
                            <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                              <div className="flex items-center">
                                <div className="h-11 w-11 flex-shrink-0">
                                  <img
                                    alt=""
                                    src={`/proimage/${pro?.image1}`}
                                    className="h-11 w-11 rounded-full"
                                  />
                                </div>
                                <div className="ml-4">
                                  {editMode &&
                                  editableProducts &&
                                  editableProducts.product_id ===
                                    pro?.product_id ? (
                                        <>
                                    <input
                                      id="title"
                                      className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                      type="text"
                                      onChange={formik.handleChange}
                                      defaultValue={pro?.title}
                                    /> <br/></>
                                  ) : (
                                    <div className="font-medium text-gray-900">
                                      {pro?.title}
                                    </div>
                                  )}{" "}
                                  {editMode &&
                                  editableProducts &&
                                  editableProducts.product_id ===
                                    pro?.product_id ? (
                                    <input
                                      id="description"
                                      className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                      type="text"
                                      onChange={formik.handleChange}
                                      defaultValue={pro?.description}
                                    />
                                  ) : (
                                    <div className="mt-1 text-gray-500">
                                      {pro?.description.substring(0, 10)}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <>
                                <input
                                  id="colors"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.colors}
                                /><br/></>
                              ) : (
                                <div className="text-gray-900">
                                  {pro?.colors}
                                </div>
                              )}
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <input
                                  id="sizes"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.sizes}
                                />
                              ) : (
                                <div className="mt-1 text-gray-500">
                                  {pro?.size1}
                                </div>
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <input
                                  id="stock"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.stock}
                                />
                              ) : pro?.stock == 10 ? (
                                <div class="flex items-center w-full mx-auto">
                                  <div class="h-2.5 w-2.5 rounded-full ml-5 bg-red-500 me-2"></div>{" "}
                                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    {pro?.stock}
                                  </span>
                                </div>
                              ) : (
                                <div class="flex items-center">
                                  <div class="h-2.5 w-2.5 rounded-full ml-5 bg-green-500 me-2"></div>{" "}
                                  <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                    {pro?.stock}
                                  </span>
                                </div>
                              )}

                        
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <input
                                  id="category"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.category}
                                />
                              ) : (
                                pro?.category
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <input
                                  id="price"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.price}
                                />
                              ) : (
                                pro?.price
                              )}
                            </td>
                            <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <input
                                  id="reutrn"
                                  className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                                  type="text"
                                  onChange={formik.handleChange}
                                  defaultValue={pro?.reutrn}
                                />
                              ) : pro?.reutrn == 1 ? (
                                <div class="flex items-center w-full mx-auto">
                                  <div class="h-2.5 w-2.5 rounded-full ml-5 bg-green-500 me-2"></div>{" "}
                                  Yes
                                </div>
                              ) : (
                                <div class="flex items-center">
                                  <div class="h-2.5 w-2.5 rounded-full ml-5 bg-red-500 me-2"></div>{" "}
                                  No
                                </div>
                              )}
                            </td>

                            <td className="px-2 py-4 flex gap-3 items-center ">
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <FaXmark
                                  className="h-4 w-4 text-red-700 cursor-pointer"
                                  onClick={handleCancelEdit}
                                  title="cancel"
                                />
                              ) : (
                                <Link
                                  to={`/seller/product/detail/${pro?.product_id}`}
                                >
                                  <FaEye
                                    className="h-4 w-4 cursor-pointer"
                                    title="details"
                                  />
                                </Link>
                              )}
                              {editMode &&
                              editableProducts &&
                              editableProducts.product_id ===
                                pro?.product_id ? (
                                <FaCheck
                                  className="h-4 w-4 text-green-700 cursor-pointer"
                                  onClick={formik.handleSubmit}
                                  title="Submit Changes"
                                />
                              ) : (
                                <BsPencilSquare
                                  className="h-4 w-4 text-green-700 cursor-pointer"
                                  onClick={() => handleEdit(pro)}
                                  title="Edit"
                                />
                              )}
                              <FaRegTrashAlt
                                className="h-4 w-4 text-red-700 cursor-pointer"
                                onClick={() => handleDelete(pro?.product_id)}
                                // onClick={() => dispatch(deleteUsers(pro?.product_id))}
                                title="Delete"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteProduct}
          id={deleteID}
        />
      )}
    </>
  );
};

export default SellerProducts;
