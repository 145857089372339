import { Disclosure, Menu } from "@headlessui/react";
import { useState } from "react";
import { GiRotaryPhone } from "react-icons/gi";
import { IoNotifications } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { FaCartArrowDown } from "react-icons/fa";
import MainNavbar from "./MainNavbar";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  getCart,
  clearErrors,
  clearMessage,
  deleteCart,
} from "../../redux/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "About Us", href: "/about", current: true },
  { name: "Order Tracking", href: "/orders", current: false },
  { name: "Contact Us", href: "/contact", current: false },
  { name: "FAQ's", href: "/faq", current: false },
];
const userNavigation = [
  { name: "Language", href: "#", current: false },
  { name: "Currency", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [cartopen, setCartOpen] = useState(false);
  const [total, setTotal] = useState();
  const { loading, user } = useSelector((state) => state.user);
  const { error, message, cart } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.user_id) {
      dispatch(getCart(user?.user_id));
    }
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [error, message, user?.user_id]);

  function handlecartSlide() {
    setCartOpen(true);
  }

  function handleRemove(cart_id) {
    dispatch(deleteCart(cart_id));
  }
  useEffect(() => {
    let price = 0;
    cart?.forEach((c) => {
      price += c?.price || 0;
    });
    setTotal(price);
  }, [cart]);

  return (
    <>
      <div className=" hidden md:block text-[12px]">
        <Disclosure as="nav" className="bg-white h-[35px]">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-2">
                <div className="flex h-8 items-center justify-between">
                  <div className="flex items-center">
                    <div className="hidden md:block">
                      <div className="ml-2 text-[12px] text-blue-500 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <Link
                            to={item.href}
                            key={item.name}
                            className={classNames(
                              item.current
                                ? "text-gray-800 hover:bg-gray-700 hover:text-white"
                                : "text-gray-800 hover:bg-gray-700 hover:text-white",
                              "rounded-md px-3 py-2  font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="relative rounded-full p-1 text-gray-800  focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Language</span>
                        Language
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div className="hidden lg:ml-8 lg:flex">
                          <Link
                            to="#"
                            className="flex items-center text-gray-700 hover:text-gray-800"
                          >
                            <img
                              src="logo.png"
                              alt=""
                              className="block h-auto w-5 flex-shrink-0"
                            />
                            <span className="ml-3 block text-sm font-medium">
                              CAD
                            </span>
                            <span className="sr-only">, change currency</span>
                          </Link>
                        </div>
                      </Menu>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </div>
      <div>
        <div className="mx-4  hidden lg:block h-[1px] bg-blue-gray-100"></div>
        <nav className="block hidden lg:block w-full py-2 px-36 mx-auto text-white bg-white">
          <div className="flex flex-wrap items-center justify-between text-white gap-y-4">
            <div className=" flex lg:ml-0">
              <Link to="#">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src="logo.png" alt="" />
              </Link>
            </div>

            <div className="relative flex w-full ml-24 md:w-max">
              <div className="relative h-10 w-full min-w-[300px]">
                <input
                  type="search"
                  className="peer h-full w-full rounded-[7px] border border-white border-t-transparent bg-transparent px-3 py-2.5 pr-20 font-sans text-sm font-normal  !text-white outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-white focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  placeholder=" "
                />
                <label className="text-blue-gray-800 absolute left-0 -top-1.5 flex text-[11px] font-normal -none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-white before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-white after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-white peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-white peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Type here...
                </label>
              </div>
              <button
                className="!absolute right-1 top-1 select-none rounded bg-blue-gray-800 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-gray-500/10 transition-all hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
              >
                Search
              </button>
            </div>
            <div className="flex justify-between gap-4 text-blue-gray-800 text-[20px] ml-auto">
              <div className="gap-2 flex text-[20px]">
                <GiRotaryPhone />
                <div className="text-[10px] font-semibold">
                  <span>Need Help?</span>
                  <br />
                  <span>+255552125555</span>
                </div>
              </div>
              <div className="flex gap-8">
                <IoNotifications />
                <FaHeart />
                <FaCartArrowDown onClick={() => handlecartSlide()} />
              </div>
            </div>
          </div>
        </nav>
        <div className="mx-4 h-[1px] bg-blue-gray-100"></div>

        <MainNavbar />
        <div className="mx-4 h-[1px] bg-blue-gray-100"></div>
      </div>
      {/* {open && */}
      <Transition show={cartopen}>
        <Dialog className="relative z-40" onClose={setCartOpen}>
          <TransitionChild
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-hidden z-40">
            <div className="absolute inset-0 overflow-hidden z-40">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 z-40">
                <TransitionChild
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <DialogTitle className="text-lg font-medium text-gray-900">
                            Shopping cart
                          </DialogTitle>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                              onClick={() => setCartOpen(false)}
                            >
                              <span className="absolute -inset-0.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          {message && (
                            <div
                              class="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3"
                              role="alert"
                            >
                              <p class="font-bold">Informational message</p>
                              <p class="text-sm">{message}</p>
                            </div>
                          )}
                        </div>

                        <div className="mt-8">
                          <div className="flow-root">
                            <ul
                              role="list"
                              className="-my-6 divide-y divide-gray-200"
                            >
                              {cart?.map((c) => (
                                <li key={c?.product_id} className="flex py-6">
                                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                    <img
                                      src={`/proimage/${c?.image1}`}
                                      // alt={product.imageAlt}
                                      className="h-full w-full object-cover object-center"
                                    />
                                  </div>

                                  <div className="ml-4 flex flex-1 flex-col">
                                    <div>
                                      <div className="flex justify-between text-base font-medium text-gray-900">
                                        <h3>
                                          <Link to={c?.href}>{c?.title}</Link>
                                        </h3>
                                        <p className="ml-4">
                                          Price : {c?.price} Rs
                                        </p>
                                      </div>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {c?.color2}
                                      </p>
                                    </div>
                                    <div className="flex flex-1 items-end justify-between text-sm">
                                      <p className="text-gray-500">
                                        Qty {c?.quantity}
                                      </p>

                                      <div className="flex">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleRemove(c?.cart_id)
                                          }
                                          className="font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                        <div className="flex justify-between text-base font-medium text-gray-900">
                          <p>Subtotal</p>
                          <p>{total} Rs</p>
                        </div>
                        <p className="mt-0.5 text-sm text-gray-500">
                          Shipping and taxes calculated at checkout.
                        </p>
                        <div className="mt-6">
                          <Link
                            to="/checkout"
                            className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                          >
                            Checkout
                          </Link>
                        </div>
                        <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                          <p>
                            or{" "}
                            <button
                              type="button"
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                              onClick={() => setCartOpen(false)}
                            >
                              Continue Shopping
                              <span aria-hidden="true"> &rarr;</span>
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* }  */}
    </>
  );
}
