// store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; 

import userReducer from "./userSlice";
import alluserReducer from "./alluserSlice";
import noticeReducer from "./noticeSlice";
import companyReducer from "./companySlice";
import productReducer from "./productSlice";
import cartReducer from "./cartSlice";
import orderReducer from "./orderSlice";





const rootReducer = combineReducers({
  user: userReducer, 
  allusers:alluserReducer,
  notice:noticeReducer,
  company:companyReducer,
  product:productReducer,
  cart:cartReducer,
  order:orderReducer

});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["cart", "notice", "company", "product","allusers","order"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
