import IconBox from "./IconBox";
import { MdGroups } from "react-icons/md";
import { IoDocumentsSharp } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { FaFacebook, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import {  FaInstagram } from "react-icons/fa6";
import Chart from "./Chart";
import NoticeBoard from "./NoticeBoard";
import UsersList from "../Users/UsersList";
import AdminCompany from "./AdminCompany";
import OrderChart from "./OrderChart";
import SocialMedia from "./SocialMedia"
export default function AdminDashboard() {
  return (
    <>
      <div className="px-2 pb-20">
        <div className="pb-4">
          <div className="h-auto w-full">
            <div className="lg:flex lg:justify-between">
              <IconBox
                icon={<MdGroups className="text-green-500 text-4xl mx-2" />}
                title={"Total User"}
                count="456846"
                textsize={"text-[15px]"}
              />
              <IconBox
                icon={<FaUsersCog className="text-yellow-800 text-4xl mx-2" />}
                title={"Total Vendor"}
                count="456846"
                textsize={"text-[15px]"}
              />
              <IconBox
                icon={
                  <IoDocumentsSharp className="text-blue-800 text-4xl mx-2" />
                }
                title={"Total Orders"}
                count="6546"
                textsize={"text-[15px]"}
              />
              <IconBox
                icon={<FaRupeeSign className="text-pink-800 text-4xl mx-2" />}
                title={"Total Earning"}
                count="456846"
                textsize={"text-[15px]"}
              />
            </div>
          </div>
        </div>
        <div className="w-full lg:flex justify-between">
          <div className=" px-2 w-3/4">
            <Chart />
          </div>
          <div className=" px-2 w-1/4">
            <NoticeBoard />
          </div>
        </div>
        <div className="mx-2 h-[50vh] overflow-y-auto mt-4 shadow-2xl">
          <UsersList role="buyer" title="Top Users" length="50" />
        </div>
        <div className="w-full lg:flex justify-between mt-4">
          <div className=" px-2 w-1/4">
            <OrderChart />
          </div>
          <div className=" mx-2 w-3/4 h-[58vh] overflow-y-auto">
            <AdminCompany title="Best Seller" />
          </div>
        </div>


        <div className="h-auto w-full mt-4">
        <div className="lg:flex lg:justify-between mx-2">
          <SocialMedia
            gradientClass={"from-blue-800 to-blue-200"}
            icon={<FaFacebook className="text-4xl mx-2" />}
            title={"Connect with Us on Facebook"}
            count={6500}
          />
          <SocialMedia
            gradientClass={"from-purple-500 to-pink-500"}
            icon={<FaInstagram className="text-4xl mx-2" />}
            title={"Follow Us on InstaGram"}
            count={4000}
          />
          <SocialMedia
            gradientClass={"from-blue-200 to-blue-800"}
            icon={<FaTwitter className="text-4xl mx-2" />}
            title={"Connect with Us on Twitter"}
            count={1500}
          />
          <SocialMedia
            gradientClass={"from-blue-800 to-blue-200"}
            icon={<FaLinkedinIn className="text-4xl mx-2" />}
            title={"Connect with Us on Linkedin"}
            count={3000}
          />
        </div>
      </div>
      </div>
    </>
  );
}
