import { clearErrors, clearMessage, getOrder } from "../../redux/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function MyOrders() {
  const { error, message, order } = useSelector((state) => state.order);
  const { loading, user } = useSelector((state) => state.user);
  const [allorder, setAllorders] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.user_id) {
      dispatch(getOrder(user?.user_id));
    }
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (order) {
      setAllorders(order);
    }
  }, [error, message, user?.user_id, dispatch]);

  function handleOrder(check){
    setAllorders( order?.filter((o)=>o.status==check))
  }
  // console.log(allorder)

  
  return (
    <>
      <div className="lg:flex">
        <div className="lg:w-1/6 py-24 items-center  mx-4">
        <div className=" border-gray-300 border-2 rounded-xl" >
          <button onClick={()=>handleOrder("placed")} className="w-full text-center p-4">Placed Orders</button><hr/>
          <button onClick={()=>handleOrder("transition")}  className="w-full text-center p-4">In transition Orders</button><hr/>
          <button onClick={()=>handleOrder("completed")}  className="w-full text-center p-4">Completed Orders</button><hr/>
          <button onClick={()=>handleOrder("canceled")}  className="w-full text-center p-4">Canceled Orders</button><hr/>
          <button onClick={()=>handleOrder("pending")}  className="w-full text-center p-4">Pending Orders</button><hr/>

        </div>
        </div>
        <div className="bg-white p-8 rounded-md lg:w-5/6">
          <div className=" flex items-center justify-between pb-6">
            <div>
              <h2 className="text-gray-600 font-semibold">Your Orders</h2>
            </div>
          </div>
          <div>
            <div className="-mx-4 sm:-mx-8 lg:px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Order Id
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Products
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Created at
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Quantity
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Amount
                      </th>
                      <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Go to
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allorder?.map((o, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex items-center">
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap">
                                #{o?.order_id}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {o?.products || 3}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {o?.created_at || "23:55:95 21/02/2024"}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {o?.quantity || 1}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                            <span className="absolute inset-0 bg-green-200 opacity-50 rounded-full"></span>
                            <span className="relative">
                              {o?.status || "pending"}
                            </span>
                          </span>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p className="text-gray-900 whitespace-no-wrap">
                            {o?.subtotal}
                          </p>
                        </td>
                        <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <Link to={`/orderdetail/${o?.order_id}`}>
                            <button
                              type="button"
                              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                            >
                              Detail
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
