import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark } from "react-icons/fa6";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  UploadProduct,
  clearErrors,
  clearMessage,
} from "../../redux/productSlice";
import { getCompanyBySellerId } from "../../redux/companySlice";
import ErrorAlert from "../../BaseFiles/ErrorAlert";

export default function SellerUploadProducts() {
  const { loading, user } = useSelector((state) => state.user);
  const { sellercompany } = useSelector((state) => state.company);
  const { message, error } = useSelector((state) => state.product);

  const [rotate, setRotate] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    seller_id: user.user_id,
    title: "",
    description: "",
    company_id: "",
    category: "",
    subcategory: "",
    gender:"",
    colors: "",
    sizes: "",
    price: "",
    stock: "",
    reutrn: "",
    files: [],
    // file2: null,
    // file3: null,
    // file4: null,
    // file5: null,
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("title is required"),
    description: Yup.string().required("description is required"),
    company_id: Yup.string().required("Company name is required"),
    category: Yup.string().required("category is required"),
    price: Yup.string().required("price is required"),
    stock: Yup.string().required("stock is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("seller_id", values.seller_id);
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("company_id", values.company_id);
      formData.append("category", values.category);
      formData.append("subcategory", values.subcategory);
      formData.append("gender", values.gender);
      formData.append("colors", values.colors);
      formData.append("sizes", values.sizes);
      formData.append("price", values.price);
      formData.append("stock", values.stock);
      formData.append("reutrn", values.reutrn);
      values?.files?.forEach((file) => {
        formData.append('file', file);
      });

      dispatch(UploadProduct(formData));
    },
  });

  useEffect(() => {
    dispatch(getCompanyBySellerId(user?.user_id));
  }, [dispatch, user?.user_id]);

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      formik.resetForm()
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (user === null) {
      navigate("/");
    }
  }, [error, dispatch, navigate, message]);
  const handleRefresh = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };
  const handleFileChange = (event, index) => {
    const files = formik.values.files.slice();
    files[index] = event.target.files[0];
    formik.setFieldValue('files', files);
  };

  return (
    <section className="py-1  w-full m-auto">
      <div className="flex flex-wrap justify-between bg-white py-2 mb-1">
        <h6 className="text-gray-700 text-xl font-semibold font-sans px-4 tracking-wider w-1/2">
          Uplaod Product
        </h6>
        <div className="w-1/2 flex gap-5 justify-end px-4 items-center">
          <FaAngleDown className="text-yellow-700 cursor-pointer" />
          <FaArrowsRotate
            className={`text-green-700 cursor-pointer ${
              rotate
                ? "rotate-180 transition-transform duration-1000"
                : "transition-transform"
            }`}
            onClick={handleRefresh}
          />
          <FaXmark className="text-red-700 cursor-pointer" />
        </div>
      </div>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div
        className={`flex bg-white justify-center ${
          loading ? "h-[560px] items-center" : "h-full"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="w-full  px-4 mx-auto mt-10 bg-white">
            <div className="flex-auto px-4 py-10 pt-0">
              <form className="py-3" onSubmit={formik.handleSubmit}>
                <h6 className="text-gray-800   text-sm px-2 mt-3 mb-6 font-bold uppercase">
                  Product Information
                  <div className="h-1 bg-gray-700 w-16 my-3"></div>
                </h6>
                <div className="flex flex-wrap mb-5">
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="title"
                      >
                        Product Title
                      </label>
                      <input
                        id="title"
                        type="text"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.title && formik.errors.title
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                    {formik.touched.title && formik.errors.title && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.title}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="description"
                      >
                        Description
                      </label>
                      <input
                        id="description"
                        type="text"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.description &&
                          formik.errors.description
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                    {formik.touched.description &&
                      formik.errors.description && (
                        <p className="text-red-500 text-xs mt-1">
                          {formik.errors.description}
                        </p>
                      )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="category"
                      >
                        Category
                      </label>
                      <select
                        id="category"
                        type="text"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.category && formik.errors.category
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Choose one</option>
                        <option value="clothing">Clothing</option>
                        <option value="accessories">Accessories</option>
                        <option value="grocery">Grocery</option>
                        <option value="mobiles">Mobiles</option>
                        <option value="homeandfurniture">Home and Furniture</option>
                        <option value="electronics">Electronics</option>
                        <option value="Appliances">appliances</option>
                      </select>
                    </div>
                    {formik.touched.category && formik.errors.category && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.category}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="company_id"
                      >
                        company_id
                      </label>
                      <select
                        id="company_id"
                        type="text"
                        value={formik.values.company_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.company_id && formik.errors.company_id
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Select Company</option>
                        {sellercompany?.map((company) => (
                          <option value={company?.company_id}>
                            {company?.company_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {formik.touched.company_id && formik.errors.company_id && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.company_id}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap mb-5">
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="colors"
                      >
                        colors
                      </label>
                      <select
                        id="colors"
                        type="text"
                        value={formik.values.colors}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.colors && formik.errors.colors
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Select Color</option>
                        <option value="red">red</option>
                        <option value="black">black</option>
                        <option value="yellow">yellow</option>
                        <option value="blue">blue</option>
                        <option value="green">green</option>
                      </select>
                    </div>
                    {formik.touched.colors && formik.errors.colors && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.colors}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="sizes"
                      >
                        sizes
                      </label>
                      <select
                        id="sizes"
                        type="text"
                        value={formik.values.sizes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.sizes && formik.errors.sizes
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Select sizes</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXl">XXl</option>
                      </select>
                    </div>
                    {formik.touched.sizes && formik.errors.sizes && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.sizes}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="price"
                      >
                        Price
                      </label>
                      <input
                        id="price"
                        type="number"
                        value={formik.values.price}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.price && formik.errors.price
                            ? "border-red-500"
                            : ""
                        }`}
                      />
                    </div>
                    {formik.touched.price && formik.errors.price && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.price}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="stock"
                      >
                        stock
                      </label>
                      <select
                        id="stock"
                        type="text"
                        value={formik.values.stock}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.stock && formik.errors.stock
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Select stock</option>
                        <option value="10">10-30</option>
                        <option value="30-50">30-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-200">100-200</option>
                      </select>
                    </div>
                    {formik.touched.stock && formik.errors.stock && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.stock}
                      </p>
                    )}
                  </div>
                </div>

                <h6 className="text-gray-800   text-sm px-2 mt-3 mb-6 font-bold uppercase">
                  Upload Product Photos
                  <div className="h-1 bg-gray-700 w-16 my-3"></div>
                </h6>
                <div className="md:flex ">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div key={index} className="w-full lg:w-3/15 px-2">
                      <label
                        className="block capitalize tracking-widest text-gray-600 text-xs font-bold mb-3"
                        htmlFor={`file${index}`}
                      >
                        File {index + 1}
                      </label>
                      <input
                        id={`file${index}`}
                        name={`file${index}`}
                        type="file"
                        accept="image/png, image/jpeg , image/jpg"
                        onChange={(event) => handleFileChange(event, index)}
                        hidden
                      />
                      <label
                        htmlFor={`file${index}`}
                        className={`border-0 px-3 py-2 placeholder-blueGray-300 focus:bg-white text-gray-600 bg-gray-200 rounded-sm text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150`}
                      >
                        {formik.values.files[index]
                          ? formik.values.files[index].name.slice(0, 23)
                          : "Choose a file"}
                      </label>
                    </div>
                  ))}
                  {formik.errors.files && formik.touched.files && (
                    <div>{formik.errors.files}</div>
                  )}
               
                </div>

                <h6 className="text-gray-800   text-sm px-2 mt-3 mb-6 font-bold uppercase">
                  Others Information
                  <div className="h-1 bg-gray-700 w-16 my-3"></div>
                </h6>
                <div className="flex flex-wrap mb-5">
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="reutrn"
                      >
                        reutrn available
                      </label>
                      <select
                        id="reutrn"
                        type="text"
                        value={formik.values.reutrn}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.reutrn && formik.errors.reutrn
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Choose one</option>
                        <option value="1">yes</option>
                        <option value="0">no</option>
                      </select>
                    </div>
                    {formik.touched.reutrn && formik.errors.reutrn && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.reutrn}
                      </p>
                    )}
                  </div>
               
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="subcategory"
                      >
                        Sub Category
                      </label>
                      <select
                        id="subcategory"
                        type="text"
                        value={formik.values.subcategory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.subcategory && formik.errors.subcategory
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Choose one</option>
                        <option value="tops">Tops</option>
                        <option value="pants">Pants</option>
                        <option value="shooes">Shooes</option>
                        <option value="sweaters">Sweaters</option>
                        <option value="tshirts">Tshirts</option>
                        <option value="jackets">Jackets</option>
                        <option value="watches">Watches</option>
                        <option value="wallets">Wallets</option>
                      </select>
                    </div>
                    {formik.touched.subcategory && formik.errors.subcategory && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.subcategory}
                      </p>
                    )}
                  </div>
                  <div className="w-full lg:w-3/12 px-2">
                    <div className="relative w-full mb-3">
                      <label
                        className="block capitalize tracking-widest text-gray-800  text-xs font-bold mb-2"
                        htmlFor="gender"
                      >
                        gender
                      </label>
                      <select
                        id="gender"
                        type="text"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border-0 px-3 py-2 placeholder-blueGray-800  focus:bg-white text-gray-800  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 ${
                          formik.touched.gender && formik.errors.gender
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="">Choose one</option>
                        <option value="men">Men</option>
                        <option value="women">Women</option>
                      </select>
                    </div>
                    {formik.touched.gender && formik.errors.gender && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.gender}
                      </p>
                    )}
                  </div>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300 py-3" />

                <div className="mx-3 flex justify-start">
                  <button
                    className="bg-green-500 text-white active:bg-yellow-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="submit"
                  >
                    Submit
                  </button>
                  <button
                    className="bg-blue-500 text-white active:bg-blue-700 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    onClick={formik.resetForm}
                  >
                    Reset Form
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
