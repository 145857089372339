import React from 'react';
import {Link} from "react-router-dom"

const ContactUs = () => {
  return (
    <>
    
    <div className='w-full'>
                <div className='container mx-auto'>
                    <section className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:px-8">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-66deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                           <h2 className='text-2xl font-bold text-center mb-0 text-black mb-0  sm:text-4xl'>Contact Us
                           </h2>
                            <figure className="mt-0">
                                <blockquote className="text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                                    <p className='text-lg mt-0 text-black-300 px-8 text-center mb-4'>We're Here to Help!
                                    </p>
                                </blockquote>
                            </figure>
                        </div>
                    </section>
                </div>
            </div>   
           

{/* <div class="container mx-auto p-8">
  <div className='col-md-6'>
        <h1 class="text-3xl font-bold text-center mb-8">Contact Us</h1>
        <p class="text-center mb-8 text-lg">We're Here to Help!</p>

        <div class="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 class="text-2xl font-semibold mb-4">Customer Support</h2>
            <p class="mb-4">For any questions about your orders, products, or our services, contact our customer support team.</p>
            <p>Email: <Link to="mailto:support@ownbasket.com" class="text-blue-500">support@ownbasket.com</Link></p>
            <p>Phone: <Link to="tel:+1234567890" class="text-blue-500">+1 234 567 890</Link></p>
            <p>Live Chat: <Link to="#" class="text-blue-500">Start a Live Chat</Link></p>
        </div>

        <div class="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 class="text-2xl font-semibold mb-4">Business Inquiries</h2>
            <p class="mb-4">For business-related questions, partnerships, or wholesale inquiries, contact our business team.</p>
            <p>Email: <Link to="mailto:business@ownbasket.com" class="text-blue-500">business@ownbasket.com</Link></p>
            <p>Phone: <Link to="tel:+1234567890" class="text-blue-500">+1 234 567 890</Link></p>
        </div>

        <div class="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 class="text-2xl font-semibold mb-4">Feedback</h2>
            <p class="mb-4">We value your feedback and are always looking to improve. Share your thoughts and suggestions with us.</p>
            <p>Email: <Link to="mailto:feedback@ownbasket.com" class="text-blue-500">feedback@ownbasket.com</Link></p>
            <p>Feedback Form: <Link to="#" class="text-blue-500">Fill Out the Feedback Form</Link></p>
        </div>

        <div class="bg-white shadow-sm border rounded-lg p-6">
            <h2 class="text-2xl font-semibold mb-4">Social Media</h2>
            <p class="mb-4">Connect with us on social media for the latest updates, promotions, and more.</p>
            <p>Facebook: <Link to="https://facebook.com/ownbasket" class="text-blue-500" target="_blank">facebook.com/ownbasket</Link></p>
            <p>Twitter: <Link to="https://twitter.com/ownbasket" class="text-blue-500" target="_blank">twitter.com/ownbasket</Link></p>
            <p>Instagram: <Link to="https://instagram.com/ownbasket" class="text-blue-500" target="_blank">instagram.com/ownbasket</Link></p>
        </div>

        <p class="text-center mt-8">Thank you for choosing OwnBasket. We look forward to hearing from you!</p>
    </div>
    </div>
    <div className='col-md-6'>
        <div className="min-h-screen bg-gray-100 flex items-center justify-center ">
      <div className="bg-white shadow-sm border rounded-lg p-8 max-w-lg w-full border mt-10">
        <h2 className="text-3xl font-semibold mb-6 text-center">Contact Us</h2>
        <p className="text-gray-700 mb-6 text-center">
          We love hearing from you. If you have any questions, feedback, or concerns, please reach out to us.
        </p>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Your email"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
            Phone:
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="phone"
            type="tel"
            placeholder="Your phone number"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
            Address:
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="address"
            placeholder="Your address"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
            Query:
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="Query"
            placeholder="Query"
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Submit
          </button>
        </div>
        <p className="text-gray-700 mt-6 text-center">
          Thank you for choosing Own Basket. We look forward to giving you a great shopping experience.
        </p>
      </div>
    </div>
    </div> */}
     <div className="container mx-auto p-8">
  <div className="flex flex-wrap">
    {/* Contact Information Column */}
    <div className="w-full md:w-1/2 p-4">
      <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Customer Support</h2>
        <p className="mb-4">For any questions about your orders, products, or our services, contact our customer support team.</p>
        <p>Email: <Link to="mailto:support@ownbasket.com" className="text-blue-500">support@ownbasket.com</Link></p>
        <p>Phone: <Link to="tel:+1234567890" className="text-blue-500">+1 234 567 890</Link></p>
      </div>
      <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Business Inquiries</h2>
        <p className="mb-4">For business-related questions, partnerships, or wholesale inquiries, contact our business team.</p>
        <p>Email: <Link to="mailto:business@ownbasket.com" className="text-blue-500">business@ownbasket.com</Link></p>
        <p>Phone: <Link to="tel:+1234567890" className="text-blue-500">+1 234 567 890</Link></p>
      </div>
      <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4">Feedback</h2>
        <p className="mb-4">We value your feedback and are always looking to improve. Share your thoughts and suggestions with us.</p>
        <p>Email: <Link to="mailto:feedback@ownbasket.com" className="text-blue-500">feedback@ownbasket.com</Link></p>
        <p>Feedback Form: <Link to="#" className="text-blue-500">Fill Out the Feedback Form</Link></p>
      </div>
      {/* <div class="container mx-auto p-8"> */}
        <div class="bg-white shadow-sm border rounded-lg p-6 mb-8 ">
            <h2 class="text-2xl font-semibold mb-0">Connect with us on Social Media</h2>
            <p class="mb-4 ">Follow us for the latest updates, promotions, and more.</p>
            <div class="flex space-x-6">
                <Link to="https://facebook.com/ownbasket" target="_blank" class="text-blue-600 hover:text-blue-800">
                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M22.675 0h-21.35C.595 0 0 .595 0 1.325v21.351C0 23.405.595 24 1.325 24H12.82V14.706h-3.166v-3.717h3.166V8.325c0-3.09 1.888-4.771 4.648-4.771 1.325 0 2.462.099 2.794.143v3.238l-1.917.001c-1.503 0-1.794.715-1.794 1.762v2.309h3.588l-.467 3.717h-3.121V24h6.117c.73 0 1.325-.595 1.325-1.325V1.325C24 .595 23.405 0 22.675 0z"/>
                    </svg>
                </Link>
                <Link to="https://twitter.com/ownbasket" target="_blank" class="text-blue-400 hover:text-blue-600">
                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M23.643 4.937c-.835.37-1.732.62-2.675.73a4.715 4.715 0 0 0 2.088-2.607c-.911.538-1.924.924-3.002 1.136A4.707 4.707 0 0 0 16.433 4c-2.607 0-4.716 2.11-4.716 4.717 0 .37.041.732.122 1.08C7.691 9.661 4.066 7.689 1.64 4.685c-.405.695-.639 1.502-.639 2.354 0 1.623.827 3.057 2.083 3.896a4.667 4.667 0 0 1-2.137-.59v.061c0 2.266 1.614 4.157 3.759 4.588a4.727 4.727 0 0 1-2.129.081 4.718 4.718 0 0 0 4.409 3.286 9.437 9.437 0 0 1-5.826 2.006c-.378 0-.747-.022-1.112-.065a13.28 13.28 0 0 0 7.19 2.109c8.623 0 13.334-7.143 13.334-13.334 0-.204-.005-.41-.014-.614a9.513 9.513 0 0 0 2.343-2.433z"/>
                    </svg>
                </Link>
                <Link to="https://instagram.com/ownbasket" target="_blank" class="text-pink-500 hover:text-pink-700">
                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.17.057 1.97.24 2.43.405.46.167.82.368 1.185.732.365.366.565.726.732 1.185.165.46.348 1.26.405 2.43.058 1.267.07 1.646.07 4.851s-.012 3.584-.07 4.85c-.057 1.17-.24 1.97-.405 2.43-.167.46-.368.82-.732 1.185-.366.365-.726.565-1.185.732-.46.165-1.26.348-2.43.405-1.267.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.057-1.97-.24-2.43-.405a3.727 3.727 0 0 1-1.185-.732 3.727 3.727 0 0 1-.732-1.185c-.165-.46-.348-1.26-.405-2.43-.058-1.267-.07-1.646-.07-4.85s.012-3.584.07-4.85c.057-1.17.24-1.97.405-2.43a3.727 3.727 0 0 1 .732-1.185 3.727 3.727 0 0 1 1.185-.732c.46-.165 1.26-.348 2.43-.405 1.267-.058 1.646-.07 4.85-.07zm0-2.163c-3.259 0-3.667.011-4.947.07-1.289.059-2.183.258-2.965.537a5.92 5.92 0 0 0-2.188 1.354 5.92 5.92 0 0 0-1.354 2.188c-.279.782-.478 1.676-.537 2.965-.059 1.28-.07 1.688-.07 4.947s.011 3.667.07 4.947c.059 1.289.258 2.183.537 2.965.314.839.722 1.586 1.354 2.188.602.631 1.349 1.04 2.188 1.354.782.279 1.676.478 2.965.537 1.28.059 1.688.07 4.947.07s3.667-.011 4.947-.07c1.289-.059 2.183-.258 2.965-.537a5.92 5.92 0 0 0 2.188-1.354 5.92 5.92 0 0 0 1.354-2.188c.279-.782.478-1.676.537-2.965.059-1.28.07-1.688.07-4.947s-.011-3.667-.07-4.947c-.059-1.289-.258-2.183-.537-2.965a5.92 5.92 0 0 0-1.354-2.188 5.92 5.92 0 0 0-2.188-1.354c-.782-.279-1.676-.478-2.965-.537-1.28-.059-1.688-.07-4.947-.07zm0 5.838c-3.403 0-6.163 2.76-6.163 6.163s2.76 6.163 6.163 6.163 6.163-2.76 6.163-6.163-2.76-6.163-6.163-6.163zm0 10.163c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.441s.645 1.441 1.441 1.441 1.441-.645 1.441-1.441-.645-1.441-1.441-1.441z"/>
                    </svg>
                </Link>
            </div>
        </div>
    {/* </div> */}

    </div>
    {/* Contact Form Column */}
    <div className="w-full md:w-1/2 p-4">
      <div className="min-h-screen bg-gray-100 flex items-center justify-center h-full">
        <div className="bg-white shadow-sm border rounded-lg p-8 max-w-lg w-full border mt-10">
          <h2 className="text-3xl font-semibold mb-6 text-center">Contact Us</h2>
          <p className="text-gray-700 mb-6 text-center">
            We love hearing from you. If you have any questions, feedback, or concerns, please reach out to us.
          </p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email:
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Your email" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
              Phone:
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="tel" placeholder="Your phone number" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
              Address:
            </label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="address" placeholder="Your address" defaultValue={""} />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="query">
              Query:
            </label>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="query" placeholder="Your query" defaultValue={""} />
          </div>
          <div className="flex items-center justify-center">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p className="text-gray-700 mt-6 text-center">Thank you for choosing Own Basket. We look forward to hearing from you!</p>        
</div>

    </>
  );
};

export default ContactUs;
