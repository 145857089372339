import React from 'react'
const About = () => {
    const cards = [
        { id: 1, name: 'Lots of Products', description: ' We offer a wide variety of items from many different sellers, giving you plenty of choices. You can find everything you need in one place.' },
        { id: 2, name: 'Fair Prices', description: ' Our competitive pricing ensures you get the best value for your money. Shop confidently knowing you are getting great deals.' },
        { id: 3, name: 'Easy Shopping', description: ' Our website is designed to be simple and easy to use, so you can shop from home without any hassle. Enjoy a smooth and convenient shopping experience.' },
        { id: 4, name: 'Great Support', description: ' Our friendly customer support team is always ready to assist you with any questions or issues. We are here to help you every step of the way.' },
    ];
    return (
        <>

            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
                <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">About Us</h2>
                        <p className="mt-6 text-lg text-justify leading-8 text-gray-300">At OwnBasket, your top place for easy online shopping. Our site connects you with many sellers, offering a wide range of products like Women's and Men's fashion, Groceries, Mobiles, Electronics, Home & Furniture, Beauty & Skin Care, and Appliances. We are here to give you the best products and great customer service.
                        </p>
                    </div>
                    {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
                </div>
            </div>
            <div className='w-full'>
                <div className='container mx-auto'>
                    <section className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:px-8">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-66deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                           <h2 className='text-2xl font-bold text-center mb-0 text-black mb-0  sm:text-4xl'>Our Mission</h2>
                            <figure className="mt-0">
                                <blockquote className="text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                                    <p className='text-lg mt-0 text-black-300 px-8 text-center mb-4'>“At OwnBasket, we aim to change how you shop online. We carefully select high-quality products that combine style, practicality, and affordability. Whether you're searching for trendy clothes, tech gadgets, home decor, or special gifts, we have everything you need.
                                    ”</p>
                                </blockquote>
                                {/* <figcaption className="mt-0">
                                    <img className="mx-auto h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Judith Black</div>
                                        <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="text-gray-600">CEO of Workcation</div>
                                    </div>
                                </figcaption> */}
                            </figure>
                        </div>
                    </section>
                </div>
            </div>
            <div className="w-full">
                <div className="container mx-auto">
                    <div className="flex flex-wrap mx-2 pt-4">
                        <div className="text-center w-full mt-4">
                            <h2 className="text-2xl font-bold  text-black mb-0  sm:text-4xl">Why Shop With Us</h2>
                            <p className="text-lg mt-0 text-black-300 px-8 text-center mb-4">At OwnBasket, we focus on making your shopping experience exceptional. Here are reasons to shop with us. </p>
                        </div>
                        {cards.map((card) => (
                            <div key={card.id} className="w-full md:w-1/2 px-2 mb-4 ">
                                <div className="bg-white rounded-lg p-4 h-full  box-shadow">
                                    <div className="flex mb-2 ">
                                        {/* <div className="mr-4 icons-set">
                                            <p className='bg-gray-300 rounded-full w-10 h-10'></p>
                                        </div> */}
                                        <div>
                                            <h4 className="text-lg font-bold">{card.name}</h4>
                                            <p className="text-gray-600">{card.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
                <div className='w-full'>
                <div className='container mx-auto'>
                    <section className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:px-8">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-66deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                           <h2 className='text-2xl font-bold text-center mb-0 text-black mb-0  sm:text-4xl'>Join Our Community
                           </h2>
                            <figure className="mt-0">
                                <blockquote className="text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                                    <p className='text-lg mt-0 text-black-300 px-8 text-center mb-4'>“Connect with us on social media to stay updated on the latest trends, promotions, and insider tips. We love hearing from you!
                                    ”</p>
                                </blockquote>
                                {/* <figcaption className="mt-0">
                                    <img className="mx-auto h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt />
                                    <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                                        <div className="font-semibold text-gray-900">Judith Black</div>
                                        <svg viewBox="0 0 2 2" width={3} height={3} aria-hidden="true" className="fill-gray-900">
                                            <circle cx={1} cy={1} r={1} />
                                        </svg>
                                        <div className="text-gray-600">CEO of Workcation</div>
                                    </div>
                                </figcaption> */}
                            </figure>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default About