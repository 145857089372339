import React from "react";
import Loader from "../../BaseFiles/Loader";
import {
  FaAngleDown,
  FaArrowsRotate,
  FaCheck,
  FaPen,
  FaXmark,
} from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  clearErrors,
  clearMessage,
  getSinglecompany,
  updateCompany
} from "../../redux/companySlice";

import { useParams } from "react-router-dom";

const CompanyProfile = () => {
  const { company_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editable, setEditable] = useState(false);
  const [rotate, setRotate] = useState(false);
  const { loading, error, message, singlecompany } = useSelector(
    (state) => state.company
  );
  const handleRefresh = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 1000);
  };
  // useEffect(() => {
  //   dispatch(getSinglecompany(company_id));
  //   if (error) {
  //     const errorInterval = setInterval(() => {
  //       dispatch(clearErrors());
  //     }, 3000);
  //     return () => clearInterval(errorInterval);
  //   }
  //   if (message) {
  //     const messageInterval = setInterval(() => {
  //       dispatch(clearMessage());
  //     }, 3000);
  //     return () => clearInterval(messageInterval);
  //   }
  // }, [dispatch, error, message, company_id]);
  let addCompanyValues = {
    company_name: "",
    gst: "",
    category: "",
  };
  const formik = useFormik({
    initialValues: addCompanyValues,
    onSubmit: (values) => {
      const filteredData = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );
      if (Object.keys(filteredData).length > 0) {
        dispatch(
          updateCompany({
            company_id: company_id,
            updatedData: filteredData,
          })
        );
        setEditable(false);
      } else {
        alert("No Changes were made");
      }
    },
  });
  return (
    <section className="py-1  w-full m-auto">
      <div className="flex flex-wrap justify-between bg-white py-2 mb-1">
        <h6 className="text-gray-700 text-xl font-semibold font-sans px-4 tracking-wider w-1/2">
          Profile {singlecompany?.[0]?.company_name}
        </h6>
        <div className="w-1/2 flex gap-5 justify-end px-4 items-center">
          <FaAngleDown className="text-yellow-700 cursor-pointer" />
          <FaArrowsRotate
            className={`text-green-700 cursor-pointer ${
              rotate
                ? "rotate-180 transition-transform duration-1000"
                : "transition-transform"
            }`}
            onClick={handleRefresh}
          />
          
           {editable ? (
                  <FaCheck
                    className="text-green-700 w-4 h-4 cursor-pointer"
                    onClick={formik.handleSubmit}
                  />
                ) : (
                  <FaPen
                    className="text-gray-700 w-4 h-4 cursor-pointer"
                    onClick={() => setEditable(true)}
                  />
                )}
                <FaXmark
                  className="text-red-700 w-5 h-5 cursor-pointer"
                  onClick={() => setEditable(false)}
                />
        </div>
      </div>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div
        className={`flex  justify-center ${
          loading ? "h-[560px] items-center" : "h-full mb-10"
        }`}
      >
        {loading ? (
          <Loader />
        ) : (
          <div className="w-full mt-2  ">
            <div className="h-auto text-sm tracking-wide ">
             
              <div className="w-1/4 pt-4 h-[60vh] shadow-2xl rounded-2xl bg-white mx-4">
                <div className="w-full p-4 px-2 flex justify-between item-center">
                  <img className="h-[20vh]" src="/default_male.png" />
                  <img className="h-[20vh]" src="/default_male.png" />

                </div>
                <div className="h-[2px] mx-4 bg-gray-700"> </div>
                <div className="flex w-full  justify-between mx-5 ">
                  <div className="w-1/2 ml-6 font-sans">
                    <ul className="capitalize mt-4 tracking-widest">
                      <li className="p-1 ">
                        <span className="  text-gray-900 font-semibold px-4">
                          ID
                        </span>
                      </li>

                      <li className="p-1">
                        <span className="   text-gray-900   font-semibold px-4">
                          Seller ID
                        </span>
                      </li>
                      <li className="p-1">
                        <span className="   text-gray-900   font-semibold px-4">
                          Name
                        </span>
                      </li>
                      <li className="p-1">
                        {" "}
                        <span className="  text-gray-900  font-semibold px-4">
                          Gst Number
                        </span>
                      </li>
                      <li className="p-1">
                        {" "}
                        <span className="   text-gray-900  font-semibold  px-4">
                          Category
                        </span>
                      </li>
                      <li className="p-1">
                        {" "}
                        <span className="   text-gray-900   font-semibold px-4">
                          Status
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="w-1/2 capitalize font-sans mt-4 text-gray-200">
                    <ul>
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="text"
                                id="company_id"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.company_id}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4 text-gray-900 items-center">
                          {singlecompany?.[0]?.company_id}
                        </li>
                      )}
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="email"
                                id="seller_id"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.seller_id}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4  text-gray-900 items-center">
                          {singlecompany?.[0]?.seller_id}
                        </li>
                      )}
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="text"
                                id="company_name"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.company_name}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4  text-gray-900 items-center">
                          {singlecompany?.[0]?.company_name}
                        </li>
                      )}
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="text"
                                id="gst"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.gst}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4  text-gray-900 items-center">
                          {singlecompany?.[0]?.gst}
                        </li>
                      )}
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="text"
                                id="category"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.category}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4  text-gray-900 items-center">
                          {singlecompany?.[0]?.category}
                        </li>
                      )}
                      {editable ? (
                        <div className="w-full">
                          <div className="relative w-full mb-1">
                            <div className=" relative">
                              <input
                                type="text"
                                id="status"
                                onChange={formik.handleChange}
                                defaultValue={singlecompany?.[0]?.status}
                                className={`border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 `}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <li className="p-1 flex gap-4  text-gray-900 items-center">
                          {singlecompany?.[0]?.status}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CompanyProfile;
