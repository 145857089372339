import React from "react";
const Policy = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
        <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
          <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
        </div>
        <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
          <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">Privacy Policy</h2>
            <p className="mt-0 text-lg  leading-8 text-gray-300 text-center px-16">Welcome to OwnBasket’s Privacy Policy page. Your privacy is very important to us. Here’s how we protect your information
            </p>
          </div>
          {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
        </div>
      </div>
      <div className="bg-gray-100 min-h-screen py-12">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <div className="px-6 py-8">
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2"> Information We Collect
                  </h3>
                  <p className="text-gray-700">
                    We collect information you provide, like your name, email, address, and payment details when you create an account or make a purchase. We also collect information about how you use our website, such as the pages you visit and the products you view.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Information We Collect</h3>
                  <p className="text-gray-700">We collect information you provide when you use our website, make purchases, or contact customer support. This may include your name, email address, shipping address, and payment information.</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">How We Use Your Information</h3>
                  <p className="text-gray-700">We use your information to process your orders, personalize your shopping experience, and improve our website. We may also use your information to send you updates about your orders, new products, and special offers.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Sharing Your Information</h3>
                  <p className="text-gray-700">We do not sell your personal information. We only share your information with trusted partners who help us provide our services, such as payment processors and delivery companies. These partners are required to keep your information safe and use it only for the services they provide.</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2"> Protecting Your Information</h3>
                  <p className="text-gray-700">We use security measures like encryption and secure servers to protect your information. We also regularly update our systems to keep your information safe.</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2"> Your Choices
                  </h3>
                  <p className="text-gray-700">You can update your account information at any time by logging into your account. You can also choose to stop receiving promotional emails by following the unsubscribe instructions in any email you receive from us.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Cookies </h3>
                  <p className="text-gray-700">OwnBasket uses cookies to enhance your shopping experience. Cookies are small files that are stored on your device. They help us remember your preferences and improve our website. You can manage your cookie settings through your browser</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">Children’s Privacy  </h3>
                  <p className="text-gray-700">OwnBasket is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected such information, we will delete it.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2"> Changes to This Policy </h3>
                  <p className="text-gray-700"> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. Please check back regularly to stay informed about how we are protecting your information</p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2"> Contact Us
                  </h3>
                  <p className="text-gray-700">If you have any questions about this Privacy Policy, please contact us at contact@ownbasket.com.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center text-gray-700">
          <p>By using OwnBasket, you agree to the terms of this Privacy Policy. Thank you for trusting OwnBasket with your information!
          </p>
        </div>
      </div>

    </>
  )
}
export default Policy;