import React from "react";
const Faqs = () => {
    return (
        <>
        <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
                <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto lg:mx-0">
                        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">Frequently Asked Questions (FAQs)</h2>
                        {/* <p className="mt-0 text-lg  leading-8 text-gray-300 text-center px-16">text pending
                        </p> */}
                    </div>
                    {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
                </div>
            </div>
         <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6"></h2>
            <div className="space-y-6">
              {/* Order and Shipping */}
              <div>
                <ul className="text-gray-700 list-disc pl-4">
                  <li>How can I track my order?</li>
                  <li>You can track your order by logging into your account and clicking on "Order History." You will find the tracking number and shipping details there.</li>
                  <li>What are your shipping options?</li>
                  <li>We offer standard, expedited, and express shipping options. The available options and costs will be displayed at checkout.</li>
                </ul>
              </div>

              {/* Returns and Refunds */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Returns and Refunds</h3>
                <ul className="text-gray-700 list-disc pl-4">
                  <li>How do I return an item?</li>
                  <li>To return an item, visit our Returns page and follow the instructions. Make sure to have your order number and item details ready.</li>
                  <li>When will I receive my refund?</li>
                  <li>Refunds are processed within 5-7 business days after we receive your returned item. The refund will be credited to your original payment method.</li>
                </ul>
              </div>

              {/* Account and Payment */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Account and Payment</h3>
                <ul className="text-gray-700 list-disc pl-4">
                  <li>How do I create an account?</li>
                  <li>Click on "Sign Up" at the top right corner of the page and fill in your details. You’ll receive a confirmation email once your account is set up.</li>
                  <li>What payment methods do you accept?</li>
                  <li>We accept various payment methods including credit/debit cards, PayPal, and more. All transactions are secure and encrypted.</li>
                </ul>
              </div>

              {/* Help Center */}
              <div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">Help Center</h3>
                <ul className="text-gray-700 list-disc pl-4">
                  <li>Product Information:</li>
                  <li>Detailed descriptions, specifications, and customer reviews are available on each product page to help you make informed decisions.</li>
                  <li>Order Issues:</li>
                  <li>If you encounter any issues with your order, such as incorrect items or missing products, please contact our support team immediately.</li>
                  <li>Technical Support:</li>
                  <li>For any technical issues with the website, such as difficulties with navigation or errors during checkout, our tech support team is here to assist you.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-3xl font-extrabold text-gray-900 mb-0">Feedback</h2>
            <div className="mt-0">
              <p className="text-gray-700">Your feedback is important to us. It helps us improve our services and better serve you. Please share your thoughts and suggestions by emailing us at <span className="font-semibold">[your feedback email]</span>.</p>
            </div>
          </div>
        </>
    );
}
export default Faqs;