import React from "react";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark, FaEye } from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Confirmation } from "../../BaseFiles/Confirmation";
import {
  clearErrors,
  clearMessage,
  getAdminOrder,
  updateOrder
} from "../../redux/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare } from "react-icons/bs";
import { FaRegTrashAlt, FaCheck, FaSearch } from "react-icons/fa";

const OrderList = (props) => {
  const currentUrl = window.location.href;
  const [editMode, setEditMode] = useState(false);
  const [editableOrder, setEditableOrder] = useState(null);
  const { loading, error, message, adminorder } = useSelector(
    (state) => state.order
  );
  const { user } = useSelector((state) => state.user);
  const [allorder, setAllorder] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(false);



  let addOrderValues = {
    user_id: "",
    product_ids: "",
    email: "",
    cardholder: "",
    cardno: "",
    creditexpiry: "",
    cvc: "",
    address: "",
    state: "",
    postal: "",
    subtotal: "",
    shipping: "",
    time: "",
  };

  useEffect(() => {
    dispatch(getAdminOrder());
    setAllorder(adminorder);
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const thds = [
    "order_id",
    "user_id",
    "product_ids",
    "email",
    "cardholder",
    "cardno",
    "creditexpiry",
    "cvc",
    "address",
    "state",
    "postal",
    "subtotal",
    "shipping",
    "time",
  ];

  const handleEdit = (pro) => {
    setEditableOrder(pro);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditableOrder(null);
  };

  const formik = useFormik({
    initialValues: addOrderValues,
    onSubmit: (values) => {
      const filteredData = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

        if (Object.keys(filteredData).length > 0) {
          dispatch(
            updateOrder({
              order_id: editableOrder.order_id,
              updatedData: filteredData,
            }
          )
          );
        }
         else {
          alert("No Changes were made");
        }

      setEditMode(false);
      setEditableOrder(null);
    },
  });

  const handleSearch = (e) => {
    setAllorder(allorder?.filter((o) => o?.includes(e.target.value)));
    setSearchQuery(e.target.value);
  };
  console.log(allorder)
  return (
    <>
      <section className="py-1  w-full m-auto">
        <div className="flex flex-wrap justify-between shadow bg-white py-2 mb-1">
          <h6 className="text-gray-700 text-xl capitalize font-semibold font-sans px-4 tracking-wider w-1/2">
            {props.title
              ? props.title
              : `${currentUrl.split("/")[3]}  ${currentUrl.split("/")[4]}`}
          </h6>
          <div className="w-1/2 flex gap-5 justify-end px-4 items-center">
            <div className="w-full px-2">
              <div className="relative w-full">
                <input
                  id="search"
                  name="search"
                  placeholder="search..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e)}
                  type="text"
                  className={`border-0 px-3 py-2 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 border-red-500`}
                />
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer">
                  <FaSearch
                    className="h-4 w-4 text-gray-600"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </div>

            <FaAngleDown className="text-yellow-700 cursor-pointer" />
            <FaArrowsRotate
              className={`text-green-700 cursor-pointer ${
                rotate
                  ? "rotate-180 transition-transform duration-1000"
                  : "transition-transform"
              }`}
              // onClick={handleRefresh}
            />
            <FaXmark className="text-red-700 cursor-pointer" />
          </div>
        </div>
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        <div
          className={`flex bg-white justify-center ${
            loading ? "h-[560px] items-center" : "h-full"
          }`}
        >
          {loading ? (
            <Loader />
          ) : (
            <table className="flex-auto mb-20 pt-0 text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400 relative overflow-x-auto shadow w-full  px-4 mx-auto  bg-white">
              <thead className="text-xs  text-gray-700 capitalize bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {thds.map((heading, index) => (
                    <th
                      scope="col"
                      key={index}
                      className={`py-4  text-xs border-b-2 ${
                        index === 0 ? "px-4" : "px-2"
                      }`}
                    >
                      {heading}
                    </th>
                  ))}

                  <th scope="col" className="py-2 px-2 text-xs border-b-2">
                    <div className="flex items-center">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {allorder?.map((ord) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    key={ord?.order_id}
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      #{ord?.order_id}
                    </th>

                    <td className="px-2 py-2">
                      <Link to={`/orderdetail/${ord?.order_id}`}>{ord?.user_id}</Link>
                    </td>

                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.ord_id === ord?.ord_id ? (
                        <input
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          id="description"
                          defaultValue={ord?.product_ids}
                          onChange={formik.handleChange}
                        />
                      ) : (
                        ord?.product_ids
                      )}
                    </td>

                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (
                        <input
                          id="email"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.email}
                        />
                      ) : (
                        ord?.email
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="cardholder"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.cardholder}
                        />
                      ) : (
                        ord?.cardholder
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="cardno"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.cardno}
                        />
                      ) : (
                        ord?.cardno
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="creditexpiry"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.creditexpiry}
                        />
                      ) : (
                        ord?.creditexpiry
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="cvc"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.cvc}
                        />
                      ) : (
                        ord?.cvc
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="address"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.address}
                        />
                      ) : (
                        ord?.address
                      )}
                    </td>

                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="state"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.state}
                        />
                      ) : (
                        ord?.state
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="postal"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.postal}
                        />
                      ) : (
                        ord?.postal
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="subtotal"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.subtotal}
                        />
                      ) : (
                        ord?.subtotal
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="shipping"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.shipping}
                        />
                      ) : (
                        ord?.shipping
                      )}
                    </td>
                    <td className="px-2 py-2">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (

                        <input
                          id="time"
                          className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                          type="text"
                          onChange={formik.handleChange}
                          defaultValue={ord?.time}
                        />
                      ) : (
                        ord?.time
                      )}
                    </td>

                    <td className="px-2 py-4 flex gap-3 items-center ">
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (
                        <FaXmark
                          className="h-4 w-4 text-red-700 cursor-pointer"
                          onClick={handleCancelEdit}
                          title="cancel"
                        />
                      ) : (
                        <Link to={`/orderdetail/${ord?.order_id}`}>
                          <FaEye
                            className="h-4 w-4 cursor-pointer"
                            title="details"
                          />
                        </Link>
                      )}
                      {editMode &&
                      editableOrder &&
                      editableOrder.order_id === ord?.order_id ? (
                        <FaCheck
                          className="h-4 w-4 text-green-700 cursor-pointer"
                          onClick={formik.handleSubmit}
                          title="Submit Changes"
                        />
                      ) : (
                        <BsPencilSquare
                          className="h-4 w-4 text-green-700 cursor-pointer"
                          onClick={() => handleEdit(ord)}
                          title="Edit"
                        />
                      )}
                      {/* <FaRegTrashAlt
                          className="h-4 w-4 text-red-700 cursor-pointer"
                          onClick={() => handleDelete(ord?.order_id)}
                            // onClick={() => dispatch(deleteUsers(pro?.product_id))}
                          title="Delete"
                        /> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </section>
      {/* {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deletet}
          id={deleteID}
        />
      )} */}
    </>
  );
};

export default OrderList;
