import React from "react";

const steps = [
    {
      title: "Browse Products",
      description: "Find a wide range of products across various categories such as fashion, electronics, home goods, beauty, and more.",
      para:" Use our search bar or navigate through categories to find what you need."
    },
    {
      title: "Select Your Items",
      description: "Click on a product to view its details, including descriptions, prices, and customer reviews.",
      para: "Choose your preferred options like size, color, or quantity, and then click 'Add to Cart."
    },
    {
      title: "Review Your Cart",
      description: "Once you've added all the items you want to purchase, click on the shopping cart icon to review your selections.",
     para:" You can adjust quantities or remove items if needed."
    },
    {
      title: "Proceed to Checkout",
      description: "Click 'Checkout' to start the payment process.",
    para:"Sign in to your account, or create a new account if you’re a new customer."
    },
    {
      title: "Enter Shipping Information",
      description: "Provide your shipping address and choose your preferred delivery method.",
    para:" Ensure all information is accurate to avoid any delays."
    },
    {
      title: "Make Payment",
      description: "Select your preferred payment method (credit card, debit card, PayPal, etc.).",
     para:" Enter your payment details securely and confirm your purchase."
    },
    {
      title: "Order Confirmation",
      description: "After your payment is processed, you will receive an order confirmation email with your order details. ",
     para:"You can track your order status through your account on our website."
    },
    {
      title: "Receive Your Items",
      description: "Your order will be shipped according to the delivery method you selected. ",
     para:"Track your shipment with the tracking number provided in your order confirmation email."
    },
    {
      title: "Enjoy and Review",
      description: "Once you receive your items, enjoy your purchase!",
     para:" We’d love to hear your feedback—leave a review to help other customers with their shopping decisions."
    }
  ];
  const additionalFeatures = [
    {
      title: "Easy Returns",
      description: "If you’re not satisfied with your purchase, our easy return policy ensures a hassle-free return process.",
     para:" Visit our Returns page for detailed instructions on how to return an item."
    },
    {
      title: "Customer Support",
      description: "Our customer support team is available 24/7 to assist you with any questions or issues.",
     para:" Contact us via email, phone, or live chat for prompt assistance."
    },
    {
      title: "Account Benefits",
      description: "Create an account to save your shipping details, track orders, and receive exclusive offers and updates. ",
    para:"Enjoy a personalized shopping experience with tailored recommendations and special discounts."
    }
  ];
const Work  = () => {
    
    return(
        <>
          <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <img src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply" alt className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center" />
                <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu" aria-hidden="true">
                    <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20" style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)' }} />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto lg:mx-0">
                        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl text-center">Works</h2>
                        <p className="mt-0 text-lg  leading-8 text-gray-300 text-center px-16">See how simple it is to shop with us and check out our easy steps for a smooth experience.
                        </p>
                    </div>
                    {/* <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            <a href="#">Open roles <span aria-hidden="true">→</span></a>
                            <a href="#">Internship program <span aria-hidden="true">→</span></a>
                            <a href="#">Our values <span aria-hidden="true">→</span></a>
                            <a href="#">Meet our leadership <span aria-hidden="true">→</span></a>
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Offices worldwide</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">12</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Full-time colleagues</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">300+</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Hours per week</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">40</dd>
                            </div>
                            <div className="flex flex-col-reverse">
                                <dt className="text-base leading-7 text-gray-300">Paid time off</dt>
                                <dd className="text-2xl font-bold leading-9 tracking-tight text-white">Unlimited</dd>
                            </div>
                        </dl>
                    </div> */}
                </div>
            </div>
            <div className="min-h-screen bg-gray-100 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">Step-by-Step Guide</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{index + 1}. {step.title}</h3>
              <ul className="pl-3 text-justify">
                <li className="text-gray-700 list-disc">
                {step.description}
                </li>
                <li className="text-gray-700 list-disc mt-1">
                {step.para}
                </li>
              </ul>
             
            </div>
          ))}
        </div>
        <h2 className="text-3xl font-extrabold text-gray-900 text-center my-12">Additional Features</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {additionalFeatures.map((feature, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>

        </>
    );
}
export default Work;