import { useState } from "react";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import { Navigate } from "react-router-dom";
import { getCart, deleteCart } from "../../redux/cartSlice";
import { clearErrors, clearMessage, placeOrder } from "../../redux/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import Spinner from "../../BaseFiles/Spinner";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

export default function CheckOut() {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [pro_ids, setPro_ids] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { loading, cart } = useSelector((state) => state.cart);
  const { error, message } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.user_id) {
      dispatch(getCart(user?.user_id));
    }
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [error, message, user?.user_id, dispatch]);

  function handleRemove(cart_id) {
    dispatch(deleteCart(cart_id));
  }

  useEffect(() => {
    let price = 0;
    let ids = new Set();
    cart?.forEach((c) => {
      price += c?.price || 0;
      ids.add(c?.product_id);
    });
    setTotal(price);
    setPro_ids(Array.from(ids));
  }, [cart]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: user?.user_id || "",
      product_ids: JSON.stringify(pro_ids),
      email: "",
      cardholder: "",
      cardno: "",
      creditexpiry: "",
      cvc: "",
      address: "",
      state: "",
      postal: "",
      subtotal: total,
      shipping: 58,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email format"),
      cardholder: Yup.string().required("Cardholder is required"),
      cardno: Yup.string()
        .required("Card number is required")
        .matches(/^\d{16}$/, "Card number must be 16 digits"),
      creditexpiry: Yup.string()
        .required("Expiry date is required")
        .matches(/^\d{2}\/\d{2}$/, "Expiry date must be in MM/YY format"),
      cvc: Yup.string()
        .required("CVC is required")
        .matches(/^\d{3,4}$/, "CVC must be 3 or 4 digits"),
      address: Yup.string().required("Address is required"),
      // state: Yup.string().required('State is required'),
      // postal: Yup.string().required('Postal code is required').matches(/^\d{5}$/, 'Postal code must be 5 digits'),
    }),
    onSubmit: async (values) => {
      dispatch(placeOrder(values));
      // navigate("/your/orders")
    },
  });

  return (
    <>
      <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32 bg-white">
        <div className="px-4 pt-8 bg-white">
          <p className="text-xl font-medium">Order Summary</p>
          <p className="text-gray-400">
            Check your items. And select a suitable shipping method.
          </p>

          <div className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
            <ul>
              {cart?.map((c) => (
                <li key={c?.product_id} className="flex py-6">
                  <div className="flex flex-col rounded-lg bg-white sm:flex-row">
                    <img
                      className="m-2 h-24 w-28 rounded-md border object-cover object-center"
                      src={`proimage/${c?.image1}`}
                      alt=""
                    />
                    <div className="flex w-full flex-col px-4 py-4">
                      <span className="font-semibold">{c?.title}</span>
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => handleRemove(c?.cart_id)}
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Remove
                        </button>
                      </div>
                      <span className="float-right text-gray-400">
                        {c?.quantity}
                      </span>
                      <p className="text-lg font-bold">{c?.price} Rs</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <p className="mt-8 text-lg font-medium">Shipping Methods</p>
          <form className="mt-5 grid gap-6">
            <div className="relative">
              <input
                className="peer hidden"
                id="radio_1"
                type="radio"
                name="radio"
                checked
              />
              <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                for="radio_1"
              >
                <img
                  className="w-14 object-contain"
                  src="/images/naorrAeygcJzX0SyNI4Y0.png"
                  alt=""
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">Fedex Delivery</span>
                  <p className="text-slate-500 text-sm leading-6">
                    Delivery: 2-4 Days
                  </p>
                </div>
              </label>
            </div>
            <div className="relative">
              <input
                className="peer hidden"
                id="radio_2"
                type="radio"
                name="radio"
                checked
              />
              <span className="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
              <label
                className="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                for="radio_2"
              >
                <img
                  className="w-14 object-contain"
                  src="/images/oG8xsl3xsOkwkMsrLGKM4.png"
                  alt=""
                />
                <div className="ml-5">
                  <span className="mt-2 font-semibold">Fedex Delivery</span>
                  <p className="text-slate-500 text-sm leading-6">
                    Delivery: 2-4 Days
                  </p>
                </div>
              </label>
            </div>
          </form>
        </div>
        <div className="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
          <form className="py-3" onSubmit={formik.handleSubmit}>
            <p className="text-xl font-medium">Payment Details</p>
            <p className="text-gray-400">
              Complete your order by providing your payment details.
            </p>
            {error && <ErrorAlert error={error} />}
            {message ? (
              <SuccessAlert message={message} />
            ) : (
              <>
                <div>
                  <div>
                    <label
                      htmlFor="email"
                      className="mt-4 mb-2 block text-sm font-medium"
                    >
                      Email
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        placeholder="your.email@gmail.com"
                      />
                      <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="cardholder"
                      className="mt-4 mb-2 block text-sm font-medium"
                    >
                      Card Holder
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="cardholder"
                        name="cardholder"
                        value={formik.values.cardholder}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Your full name here"
                      />
                      <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    {formik.touched.cardholder && formik.errors.cardholder && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.cardholder}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="cardno"
                      className="mt-4 mb-2 block text-sm font-medium"
                    >
                      Card Details
                    </label>
                    <div className="flex w-full">
                      <div className="relative w-6/12 flex-shrink-0">
                        <input
                          type="text"
                          id="cardno"
                          name="cardno"
                          value={formik.values.cardno}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="xxxx-xxxx-xxxx-xxxx"
                        />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                          <svg
                            className="h-4 w-4 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                          </svg>
                        </div>
                        {formik.touched.cardno && formik.errors.cardno && (
                          <p className="text-red-500 text-xs mt-1">
                            {formik.errors.cardno}
                          </p>
                        )}
                      </div>
                      <div className="w-3/12">
                        <input
                          type="text"
                          name="creditexpiry"
                          id="creditexpiry"
                          className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="MM/YY"
                          value={formik.values.creditexpiry}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.creditexpiry &&
                          formik.errors.creditexpiry && (
                            <p className="text-red-500 text-xs mt-1">
                              {formik.errors.creditexpiry}
                            </p>
                          )}
                      </div>
                      <div className="w-3/12">
                        <input
                          type="text"
                          name="cvc"
                          id="cvc"
                          className="w-full flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="CVC"
                          value={formik.values.cvc}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.cvc && formik.errors.cvc && (
                          <p className="text-red-500 text-xs mt-1">
                            {formik.errors.cvc}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="address"
                      className="mt-4 mb-2 block text-sm font-medium"
                    >
                      Billing Address
                    </label>
                    <div className="flex flex-col sm:flex-row">
                      <div className="relative flex-shrink-0 sm:w-7/12">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Street Address"
                        />
                        <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                          <img
                            className="h-4 w-4 object-contain"
                            src="https://flagpack.xyz/_nuxt/4c829b6c0131de7162790d2f897a90fd.svg"
                            alt=""
                          />
                        </div>
                        {formik.touched.address && formik.errors.address && (
                          <p className="text-red-500 text-xs mt-1">
                            {formik.errors.address}
                          </p>
                        )}
                      </div>
                      <div>
                        <select
                          type="text"
                          id="state"
                          name="state"
                          value={formik.values.state}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                        >
                          <option value="State">State</option>
                        </select>
                        {formik.touched.state && formik.errors.state && (
                          <p className="text-red-500 text-xs mt-1">
                            {formik.errors.state}
                          </p>
                        )}
                      </div>
                      <div>
                        <input
                          type="text"
                          name="postal"
                          id="postal"
                          className="flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="ZIP"
                          value={formik.values.postal}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.postal && formik.errors.postal && (
                          <p className="text-red-500 text-xs mt-1">
                            {formik.errors.postal}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 border-t border-b py-2">
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        Subtotal
                      </p>
                      <p className="font-semibold text-gray-900">{total}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">
                        Shipping
                      </p>
                      <p className="font-semibold text-gray-900">58.00 Rs</p>
                    </div>
                  </div>
                  <div className="mt-6 flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Total</p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {total + 58}
                    </p>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
                >
                  {loading ? <Spinner /> : "Place Order"}
                </button>{" "}
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
