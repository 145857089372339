import TempProCard from "./TempProCard";
import { getProduct } from "../../redux/productSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
export default function HomePageProduct({ title, subtitle }) {
  const { loading, error, message, product } = useSelector(
    (state) => state.product
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProduct());
  }, []);
  return (
    <section class="py-12 bg-white sm:py-16 lg:py-20">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-md mx-auto text-center">
          <h2 class="text-2xl font-bold text-gray-900 sm:text-3xl">{title}</h2>
          <p class="mt-4 text-base font-normal leading-7 text-gray-600">
            {subtitle}
          </p>
        </div>
        <div class="grid grid-cols-2 gap-6 mt-10 lg:mt-16 lg:gap-4 lg:grid-cols-4">
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            product?.map((item) => (
              <TempProCard key={item.product_id} product={item} />
            ))
          )}
        </div>
      </div>
    </section>
  );
}
