
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getNotice = createAsyncThunk(
  "staff/getNotice",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.ownbasket.shop/api/v1/notice");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const deleteNotice = createAsyncThunk(
  "staff/deleteNotice",
  async (notice_id, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.ownbasket.shop/api/v1/notice/${notice_id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { noticeId: notice_id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



const initialState = {
  notice:null,
  loading: false,
  error: null,
  message: null,
};

const noticeSlice = createSlice({
  name: "notice",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.notice = action.payload.notice;
      })
      .addCase(getNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteNotice.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        state.notice = state.notice.filter(
          (n) => n.notice_id !== action.payload.noticeId
        );
      })
      .addCase(deleteNotice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = noticeSlice.actions;

export default noticeSlice.reducer;
