import React from 'react'

const Career = () => {
    return (
        <>
            <div className='w-full'>
                <div className='container mx-auto'>
                    <section className="relative isolate overflow-hidden bg-white px-6 py-16 sm:py-16 lg:px-8">
                        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-66deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                        <div className="mx-auto max-w-2xl lg:max-w-4xl">
                            <h2 className='text-2xl font-bold text-center mb-0 text-black mb-0  sm:text-4xl'>Join the OwnBasket Team!
                            </h2>
                            <figure className="mt-0">
                                <blockquote className="text-center text-xl font-semibold text-gray-900 sm:text-2xl">
                                    <p className='text-lg mt-0 text-black-300 px-8 text-center mb-4'>Are you passionate about e-commerce and looking to be part of a dynamic and innovative team? At OwnBasket, we are always on the lookout for talented individuals who are ready to make an impact. Explore our career opportunities and discover how you can grow with us.
                                    </p>
                                </blockquote>
                            </figure>
                        </div>
                    </section>
                </div>
            </div>
            <div className="container mx-auto p-8">
                {/* Why Work With Us Section */}
                <div className="bg-white shadow-sm border rounded-lg p-6 mb-12">
                    <h2 className="text-3xl font-semibold mb-6">Why Work With Us?</h2>
                    <ul className="list-disc list-inside space-y-4">
                        <li><strong>Innovative Environment:</strong> Be part of a forward-thinking company that is shaping the future of online shopping.</li>
                        <li><strong>Growth Opportunities:</strong> We offer numerous opportunities for professional development and career advancement.</li>
                        <li><strong>Collaborative Culture:</strong> Work in a team-oriented environment where your ideas and contributions are valued.</li>
                        <li><strong>Work-Life Balance:</strong> We believe in maintaining a healthy work-life balance and offer flexible work arrangements.</li>
                    </ul>
                </div>
                {/* Current Openings Section */}
                <div className="bg-white shadow-sm border rounded-lg p-6 mb-12">
                    <h2 className="text-3xl font-semibold mb-6">Current Openings</h2>
                    <div className="space-y-8">
                        <div>
                            <h3 className="text-2xl font-bold mb-2">Customer Support Representative</h3>
                            <p><strong>Responsibilities:</strong> Assist customers with their inquiries, resolve issues, and provide exceptional service.</p>
                            <p><strong>Qualifications:</strong> Strong communication skills, problem-solving abilities, and a customer-centric approach.</p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-2">Marketing Specialist</h3>
                            <p><strong>Responsibilities:</strong> Develop and execute marketing campaigns, manage social media accounts, and analyze campaign performance.</p>
                            <p><strong>Qualifications:</strong> Experience in marketing, strong analytical skills, and creativity.</p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-2">Software Developer</h3>
                            <p><strong>Responsibilities:</strong> Design, develop, and maintain our e-commerce platform and related applications.</p>
                            <p><strong>Qualifications:</strong> Proficiency in programming languages, experience with web development, and problem-solving skills.</p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-bold mb-2">Product Manager</h3>
                            <p><strong>Responsibilities:</strong> Oversee product development, manage product lifecycle, and collaborate with cross-functional teams.</p>
                            <p><strong>Qualifications:</strong> Experience in product management, strong organizational skills, and strategic thinking.</p>
                        </div>
                    </div>
                </div>
                {/* How to Apply Section */}
                <div className="bg-white shadow-sm border rounded-lg p-6">
                    <h2 className="text-3xl font-semibold mb-6">How to Apply</h2>
                    <p className="mb-4">If you are interested in joining the OwnBasket team, please send your resume and a cover letter to <a href="mailto:careers@ownbasket.com" className="text-blue-500">careers@ownbasket.com</a>. In your cover letter, let us know why you would be a great fit for OwnBasket and how you can contribute to our success.</p>
                    <p>Join us and be a part of the exciting journey at OwnBasket. We look forward to welcoming you to our team!</p>
                </div>
            </div>


        </>
    )
}

export default Career;