import React from "react";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark, FaEye } from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Confirmation } from "../../BaseFiles/Confirmation";
import {
  clearErrors,
  clearMessage,
  getCompany,
  deleteCompany,
  updateCompany,
} from "../../redux/companySlice";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare } from "react-icons/bs";
import { FaRegTrashAlt, FaCheck, FaSearch } from "react-icons/fa";

const AdminCompany = (props) => {
  const currentUrl = window.location.href;
  const [editMode, setEditMode] = useState(false);
  const [editableCompany, seteditableCompany] = useState(null);
  const [modalopen, setModalopen] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const { loading, error, message, company} = useSelector(
    (state) => state.company
  );
  const { user } = useSelector((state) => state.user);
  const [allcompany, setAllcompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [rotate, setRotate] = useState(false);

  function isClose() {
    setModalopen(false);
  }
  function handleDelete(id) {
    setdeleteID(id);
    if (deleteID) {
      setModalopen(true);
    }
  }
  let addUsersValues = {
    company_name: "",
    gst: "",
    category: "",
    email:"",
    phone:"",
    status:"",
  };
  console.log(user?.role);
  useEffect(() => {
   
      setAllcompany(company);
      dispatch(getCompany());

    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, user?.role]);

  const thds = [
    "Company id",
    "logo",
    "email",
    "phone",
    "Company name",
    "gst number",
    "category",
    "status",
  ];

  const handleEdit = (ac) => {
    seteditableCompany(ac);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    seteditableCompany(null);
  };

  const formik = useFormik({
    initialValues: addUsersValues,
    onSubmit: (values) => {
      const filteredData = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

      if (Object.keys(filteredData).length > 0) {
        dispatch(
          updateCompany({
            company_id: editableCompany.company_id,
            updatedData: filteredData,
          })
        );
      } else {
        alert("No Changes were made");
      }

      setEditMode(false);
      seteditableCompany(null);
    },
  });

  const handleSearch = (e) => {
    setAllcompany(
      company?.filter((c) => c.company_name?.includes(e.target.value))
    );
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <section className="py-1  w-full m-auto ">
        <div className="flex flex-wrap justify-between shadow bg-white py-2 mb-1">
        <h6 className="text-gray-700 text-xl capitalize font-semibold font-sans px-4 tracking-wider w-1/2">
          {props.title ? (props.title) : (`${currentUrl.split("/")[3]}  ${currentUrl.split("/")[4]}`)}
        </h6>
          <div className="w-1/2 flex gap-5 justify-end px-4 items-center">
            <div className="w-full px-2">
              <div className="relative w-full">
                <input
                  id="search"
                  name="search"
                  placeholder="search..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e)}
                  type="text"
                  className={`border-0 px-3 py-2 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150 border-red-500`}
                />
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600 cursor-pointer">
                  <FaSearch
                    className="h-4 w-4 text-gray-600"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </div>

            <FaAngleDown className="text-yellow-700 cursor-pointer" />
            <FaArrowsRotate
              className={`text-green-700 cursor-pointer ${
                rotate
                  ? "rotate-180 transition-transform duration-1000"
                  : "transition-transform"
              }`}
              // onClick={handleRefresh}
            />
            <FaXmark className="text-red-700 cursor-pointer" />
          </div>
        </div>
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        <div
          className={`flex bg-white justify-center ${
            loading ? "h-[560px] items-center" : "h-full"
          }`}
        >
          {loading ? (
            <Loader />
          ) : (
            <table className="flex-auto mb-20 pt-0 text-xs text-left rtl:text-right text-gray-500 dark:text-gray-400 relative overflow-x-auto shadow w-full  px-4 mx-auto  bg-white">
              <thead className="text-xs  text-gray-700 capitalize bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {thds.map((heading, index) => (
                    <th
                      scope="col"
                      key={index}
                      className={`py-4  text-xs border-b-2 ${
                        index === 0 ? "px-4" : "px-2"
                      }`}
                    >
                      {heading}
                    </th>
                  ))}

                  <th scope="col" className="py-2 px-2 text-xs border-b-2">
                    <div className="flex items-center">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody>
              {allcompany?.map((ac, index) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      key={ac?.company_id}
                    >
                      <th
                        scope="row"
                        className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {ac?.company_id}
                      </th>
                      <td className="px-2 py-2 w-10">
                      <img
                            className="w-10 h-10 mb-3 rounded-full shadow-lg"
                            src={`/companylogo/${ac?.avatar}`}
                            alt="Bonnie image"
                          />
                      </td>
                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            id="phone"
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={ac?.phone}
                          />
                        ) : (
                            ac?.phone
                        )}
                      </td>
                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="email"
                            id="email"
                            defaultValue={ac?.email}
                            onChange={formik.handleChange}
                          />
                        ) : (
                          ac?.email
                        )}
                      </td>

                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            id="company_name"
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={ac?.company_name}
                          />
                        ) : (
                          ac?.company_name
                        )}
                      </td>
                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            id="gst"
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={ac?.gst}
                          />
                        ) : (
                          ac?.gst
                        )}
                      </td>

                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            id="category"
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={ac?.category}
                          />
                        ) : (
                          ac?.category
                        )}
                      </td>

                      <td className="px-2 py-2">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <input
                            id="status"
                            className="border-0 px-3 py-1 placeholder-blueGray-300  focus:bg-white text-gray-600  bg-gray-200 rounded-sm text-sm shadow focus:outline-none  w-full ease-linear transition-all duration-150"
                            type="text"
                            onChange={formik.handleChange}
                            defaultValue={ac?.status}
                          />
                        ) : (
                          ac?.status
                        )}
                      </td>
               

                      <td className="px-2 py-4 flex gap-3 items-center ">
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <FaXmark
                            className="h-4 w-4 text-red-700 cursor-pointer"
                            onClick={handleCancelEdit}
                            title="cancel"
                          />
                        ) : (
                          <Link to={`/company/details/${ac?.company_id}`}>
                            <FaEye
                              className="h-4 w-4 cursor-pointer"
                              title="details"
                            />
                          </Link>
                        )}
                        {editMode &&
                        editableCompany &&
                        editableCompany.company_id === ac?.company_id ? (
                          <FaCheck
                            className="h-4 w-4 text-green-700 cursor-pointer"
                            onClick={formik.handleSubmit}
                            title="Submit Changes"
                          />
                        ) : (
                          <BsPencilSquare
                            className="h-4 w-4 text-green-700 cursor-pointer"
                            onClick={() => handleEdit(ac)}
                            title="Edit"
                          />
                        )}
                        <FaRegTrashAlt
                          className="h-4 w-4 text-red-700 cursor-pointer"
                          onClick={() => handleDelete(ac?.company_id)}
                          //   onClick={() => dispatch(deleteUsers(ac.company_id))}
                          title="Delete"
                        />
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
        </div>
      </section>
      {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteCompany}
          id={deleteID}
        />
      )}
    </>
  );
};

export default AdminCompany;
