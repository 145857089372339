import "./App.css";
import Layout from "./BaseFiles/Layout";
import Registration from "./pages/Registration";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./BaseFiles/PrivateRoutes";
import AdminDashboard from "./component/dashboard/AdminDashboard";
import { useDispatch, useSelector } from "react-redux";
import UsersList from "./component/Users/UsersList";
import BecomeSeller from "./component/seller/BecomeSeller";
import Company from "./component/seller/Company";
import UserProfile from "./component/Users/UserProfile";
import CompanyProfile from "./component/seller/CompanyProfile";
import UploadProducts from "./component/products/UploadProducts";
import AllProducts from "./component/products/AllProducts";
import Home from "./UIUX/Home/Home";
import ProductPage from "./UIUX/Product/ProductPage";
import ProductDetail from "./UIUX/Product/ProductDetail";
import InDevelopment from "./BaseFiles/InDevelopment";
import CheckOut from "./UIUX/Product/Checkout";
import ComingSoon from "./UIUX/comman/ComingSoon";
import MyOrders from "./UIUX/Product/MyOrders";
import HomeLayout from "./UIUX/comman/HomeLayout";
import ShowProduct from "./UIUX/Product/ShowProduct";
import OrderDetail from "./UIUX/Product/OrderDetail";
import OrderList from "./component/products/OrderList";
import UserAccount from "./UIUX/comman/UserAccount";
import UserProfileDetail from "./UIUX/comman/UserProfileDetail";
import About from "./UIUX/extraPages/about";
import Career from "./UIUX/extraPages/career";

import Conditions from "./UIUX/extraPages/conditions";
import ContactUs from "./UIUX/extraPages/contactUs";
import Customer from "./UIUX/extraPages/customer";
import Delivery from "./UIUX/extraPages/delivery";
import Faqs from "./UIUX/extraPages/faqs";
import Features from "./UIUX/extraPages/features";
import Policy from "./UIUX/extraPages/policys";
import Work from "./UIUX/extraPages/works";
import SellerSideBar from "./component/seller/SellerSideBar";
import SellerDashboard from "./component/seller/SellerDashboard";
import SellerOrder from "./component/seller/SellerOrder";
import SellerProducts from "./component/seller/SellerProduct";
import SellerUploadProducts from "./component/seller/SellerUploadProducts";
import AdminLogin from "./pages/AdminLogin";
import AdminCompany from "./component/dashboard/AdminCompany";
function App() {
  const { user } = useSelector((state) => state.user);
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route
            path={`/admin/dashboard`}
            element={
              <Layout>
                <AdminDashboard />
              </Layout>
            }
          />
          <Route
            path={`/all/buyer`}
            element={
              <Layout>
                <UsersList role="buyer" length="all" />
              </Layout>
            }
          />

          <Route
            path={`/all/seller`}
            element={
              <Layout>
                <UsersList role="seller" length="all" />
              </Layout>
            }
          />
          <Route
            path={`/add/company`}
            element={<SellerSideBar child={<BecomeSeller />} />}
          />
          <Route
            path={`/admin/company`}
            element={
              <Layout>
                <AdminCompany />
              </Layout>
            }
          />
          <Route
            path={`/user/details/:user_id`}
            element={
              <Layout>
                <UserProfile />
              </Layout>
            }
          />
          <Route
            path={`/company/details/:company_id`}
            element={
              <Layout>
                <CompanyProfile />
              </Layout>
            }
          />
          <Route
            path={`/upload/products`}
            element={
              <Layout>
                <UploadProducts />
              </Layout>
            }
          />
          <Route
            path={`/order/list`}
            element={
              <Layout>
                <OrderList />
              </Layout>
            }
          />

          <Route
            path={`/orderdetail/:order_id`}
            element={
              <HomeLayout>
                <OrderDetail />
              </HomeLayout>
            }
          />
          <Route
            path={`/user/account`}
            element={
              <HomeLayout>
                <UserAccount />
              </HomeLayout>
            }
          />
          <Route
            path={`/user/profile/detail`}
            element={
              <HomeLayout>
                <UserProfileDetail />
              </HomeLayout>
            }
          />
          <Route
            path={`/orders`}
            element={
              <HomeLayout>
                <MyOrders />
              </HomeLayout>
            }
          />
          <Route
            path={`/buyer/add/company`}
            element={
              <HomeLayout>
                <BecomeSeller />
              </HomeLayout>
            }
          />

          <Route
            path="/seller/dashboard"
            element={<SellerSideBar child={<SellerDashboard />} />}
          />
          <Route
            path="/seller/company"
            element={<SellerSideBar child={<Company />} />}
          />
          <Route
            path="/seller/order"
            element={<SellerSideBar child={<SellerOrder />} />}
          />
          <Route
            path="/seller/product"
            element={<SellerSideBar child={<SellerProducts />} />}
          />
          <Route
            path="/seller/uploadproduct"
            element={<SellerSideBar child={<SellerUploadProducts />} />}
          />
          <Route
            path="/seller/product/detail/:pro_id"
            element={<SellerSideBar child={<ProductDetail />} />}
          />
          <Route
            path={`/checkout`}
            element={
              <HomeLayout>
                <CheckOut />
              </HomeLayout>
            }
          />
        </Route>
        <Route
          path={`/products/:category`}
          element={
            <HomeLayout>
              <ProductPage />
            </HomeLayout>
          }
        />
        <Route
          path={`/product/detail/:pro_id`}
          element={
            <HomeLayout>
              <ProductDetail />
            </HomeLayout>
          }
        />
        <Route
          path={`/all/products`}
          element={
            <Layout>
              <AllProducts />
            </Layout>
          }
        />
        <Route
          path={`/indevelopment`}
          element={
            <Layout>
              <InDevelopment />
            </Layout>
          }
        />
        {/* </Route> */}
        {/* ui ux </Route> */}
        <Route
          path={`/`}
          element={
            <HomeLayout>
              <Home />
            </HomeLayout>
          }
        />
        <Route
          path={`/products/:category/:gender/:subcategory`}
          element={
            <HomeLayout>
              <ShowProduct />
            </HomeLayout>
          }
        />
        <Route
          path={`/comingsoon`}
          element={
            <HomeLayout>
              <ComingSoon />
            </HomeLayout>
          }
        />{" "}
        <Route
          path={`/about`}
          element={
            <HomeLayout>
              <About />
            </HomeLayout>
          }
        />
        <Route
          path={`/career`}
          element={
            <HomeLayout>
              <Career />
            </HomeLayout>
          }
        />
        <Route
          path={`/terms`}
          element={
            <HomeLayout>
              <Conditions />
            </HomeLayout>
          }
        />
        <Route
          path={`/contact`}
          element={
            <HomeLayout>
              <ContactUs />
            </HomeLayout>
          }
        />
        <Route
          path={`/support`}
          element={
            <HomeLayout>
              <Customer />
            </HomeLayout>
          }
        />
        <Route
          path={`/delivery`}
          element={
            <HomeLayout>
              <Delivery />
            </HomeLayout>
          }
        />
        <Route
          path={`/faq`}
          element={
            <HomeLayout>
              <Faqs />
            </HomeLayout>
          }
        />
        <Route
          path={`/feature`}
          element={
            <HomeLayout>
              <Features />
            </HomeLayout>
          }
        />
        <Route
          path={`/policy`}
          element={
            <HomeLayout>
              <Policy />
            </HomeLayout>
          }
        />
        <Route
          path={`/work`}
          element={
            <HomeLayout>
              <Work />
            </HomeLayout>
          }
        />
        <Route path="/registration" element={<Registration />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
