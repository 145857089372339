import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNotice, deleteNotice } from "../../redux/noticeSlice"

import { clearMessage, clearErrors } from "../../redux/noticeSlice";
const ListItem = ({ noti }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const dispatch = useDispatch();
  const { error, message } = useSelector((state) => state.notice);
 
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const handleDelete = (id) => {
    dispatch(deleteNotice(id));
  };
  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(clearErrors());
      }, 3000);
    }
    if (message) {
      setTimeout(() => {
        dispatch(getNotice());
      }, 3000);
    }
  }, [error, message]);
  return (
    <li className="flex flex-col  ">
      <div className=" ml-4">
        <span className="text-gray-400 text-[12px]">{noti.date}</span>
        <br />
        <span className="text-indigo-500 text-[16px]">{noti.title}</span>{" "}
        <span className="text-gray-400 text-[12px]">{noti.time}</span>
      </div>
      <div className="ml-4 ">
        <span className="text-black text-[16px]">
          {isReadMore ? noti?.content?.slice(0, 40) : noti.content}
        </span>
        {noti?.content?.length > 40 && (
          <button onClick={toggleReadMore} className="text-red-500 text-sm">
            {isReadMore ? "(Read more)" : "(Show less)"}
          </button>
        )}
      </div>

        <div className="px-5 flex gap-1">
        
          <button
            onClick={() => {
              handleDelete(noti.notice_id);
            }}
            className="text-red-600 hover:underline cursor-pointer text-xs"
          >
            Delete
          </button>
        </div>
      <hr className="mt-1" />
    </li>
  );
};

const NoticeBoard = ({ refresh, setRefresh }) => {
  const { notice, error, message } = useSelector((state) => state.notice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotice());
    if (error) {
      setTimeout(() => {
        dispatch(clearErrors());
      }, 3000);
    }
    if (message) {
      setTimeout(() => {
        dispatch(clearMessage());
      }, 3000);
    }
  }, [error, message]);

  return (
    <>
    <div className="bg-white h-[57vh] overflow-y-auto">
      <div className="uppercase text-2xl bg-gray-200 text-gray-800 text-center font-semibold p-4 pb-0">Notification</div>
      <ul className="list-none pl-0">
        {notice?.map((noti, index) => (
          <ListItem key={index} noti={noti} />
        ))}
      </ul>
    </div>
    </>
  );
};

export default NoticeBoard;
