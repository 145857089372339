import { ChatBubbleLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import React from "react";
import { FaAngleDown, FaArrowsRotate, FaXmark, FaEye } from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import Loader from "../../BaseFiles/Loader";
import OrderDetail from "./OrderDetail";
import { Confirmation } from "../../BaseFiles/Confirmation";
import { saveAs } from 'file-saver';


import {
  clearErrors,
  clearMessage,
  getSellerOrderById,
  deleteOrder,
  updateOrder,
} from "../../redux/orderSlice";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare } from "react-icons/bs";
import { FaRegTrashAlt, FaCheck, FaSearch } from "react-icons/fa";
export default function SellerOrder() {

    const [editMode, setEditMode] = useState(false);
    const [editableOrder, seteditableOrder] = useState(null);
    const [modalopen, setModalopen] = useState(false);
    const [so, setSo] = useState();
    const { loading, error, message, company, sellerorder } = useSelector(
      (state) => state.order
    );
    const { user } = useSelector((state) => state.user);
    const [allsellerorder, setAllsellerorder] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const dispatch = useDispatch();
    const [rotate, setRotate] = useState(false);
  
    function isClose() {
      setModalopen(false);
    }
    function handleInfo(so) {
      setSo(so);
      if (setSo) {
        setModalopen(true);
      }
    }
    let addUsersValues = {
      status: "",
    };
    useEffect(() => {
        setAllsellerorder(sellerorder);
        dispatch(getSellerOrderById(user?.user_id));
      if (error) {
        const errorInterval = setInterval(() => {
          dispatch(clearErrors());
        }, 3000);
        return () => clearInterval(errorInterval);
      }
      if (message) {
        const messageInterval = setInterval(() => {
          dispatch(clearMessage());
        }, 3000);
        return () => clearInterval(messageInterval);
      }
    }, [dispatch, error, message, user?.role, allsellerorder]);
  

    const handleEdit = (ord) => {
      seteditableOrder(ord);
      setEditMode(true);
    };
  
    const handleCancelEdit = () => {
      setEditMode(false);
      seteditableOrder(null);
    };
  
    const formik = useFormik({
      initialValues: addUsersValues,
      onSubmit: (values) => {
        const filteredData = Object.fromEntries(
          Object.entries(values).filter(
            ([key, value]) => value !== "" && value !== null
          )
        );
  
        if (Object.keys(filteredData).length > 0) {
          dispatch(
            updateOrder({
              order_id: editableOrder.order_id,
              updatedData: filteredData,
            })
          );
        } else {
          alert("No Changes were made");
        }
  
        setEditMode(false);
        seteditableOrder(null);
      },
    });
  
    const handleSearch = (e) => {
      setAllsellerorder(
        sellerorder?.filter((o) => o.order_id?.includes(e.target.value))
      );
      setSearchQuery(e.target.value);
    };
  
    const downloadCSV = () => {
      const headers = Object.keys(allsellerorder?.[0]);
      const csvRows = [
        headers.join(','),
        ...allsellerorder?.map(row =>
          headers.map(header => JSON.stringify(row[header] || '')).join(',')
        )
      ];
  
      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'data.csv');
    };
  return (
    <>
    <div className="px-4 sm:px-6 lg:px-8">
      
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div
              className={`flex bg-white justify-center ${
                loading ? "h-[560px] items-center" : "h-full"
              }`}
            >
              {loading ? (
                <Loader />
              ) : (
                <div className="px-2 sm:px-2 lg:px-2 w-full">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Orders</h1>
                    <p className="mt-2 text-sm text-gray-700">
                      A table of Orders data .
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button onClick={downloadCSV}
                      type="button"
                      className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Export
                    </button>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                            >
                              Order ID
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Product ID
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Product Title
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Product category
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Product Brand
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Total amount
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Status
                            </th>
                           
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Action
                            </th>
                            
                          
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {sellerorder?.map((so) => (
                            <tr key={so?.order_id}>
                              <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{so?.order_id}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                {so?.product_id}
                              </td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{so?.title}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{so?.category}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{so?.brand_name}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{so?.price + so?.shipping}</td>
                              <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{so?.status}</td>
                              <td onClick={()=>handleInfo(so)} className="whitespace-nowrap px-2 py-2 text-sm font-bold text-blue-700">Info</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

    {modalopen && (
      <OrderDetail
      modalopen={modalopen}
      modalClose={setModalopen}
        so={so}
      />
    )}
  </>
  )
}
