// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const companyRegistration = createAsyncThunk(
  "user/companyRegistration",
  async (formData, thunkAPI) => {
    try {
      const response = await fetch('https://api.ownbasket.shop/api/v1/company/Registration', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData);
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getCompany = createAsyncThunk(
  "staff/getCompany",
  async (_, thunkAPI) => {
    try {
      const response = await fetch("https://api.ownbasket.shop/api/v1/company");

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getSinglecompany = createAsyncThunk(
  "staff/getSinglecompany",
  async (company_id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.ownbasket.shop/api/v1/company/singlecompany/${company_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getCompanyBySellerId = createAsyncThunk(
  "staff/getCompanyBySellerId",
  async (seller_id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.ownbasket.shop/api/v1/company/sellercompany/${seller_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
// export const deleteNotice = createAsyncThunk(
//   "staff/deleteNotice",
//   async (notice_id, thunkAPI) => {
//     try {
//       // Your asynchronous logic to delete student here
//       const response = await fetch(`https://api.ownbasket.shop/api/v1/notice/${notice_id}`, {
//         method: "DELETE",
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }
//       const data = await response.json();
//       return { noticeId: notice_id, message: data.message };
//     } catch (error) {
//       // Handle error
//       return thunkAPI.rejectWithValue({ error: error.message });
//     }
//   }
// );

export const deleteCompany = createAsyncThunk(
  "staff/deleteCompany",
  async (company_id, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.ownbasket.shop/api/v1/company/${company_id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { companyId: company_id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateCompany = createAsyncThunk(
  "student/updateCompany",
  async ({ company_id, updatedData }, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.ownbasket.shop/api/v1/company/${company_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


const initialState = {
  company: null,
  singlecompany: null,
  sellercompany:null,
  loading: false,
  error: null,
  message: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(companyRegistration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(companyRegistration.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(companyRegistration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.company = action.payload.company;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getSinglecompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSinglecompany.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.singlecompany = action.payload.singlecompany;
      })
      .addCase(getSinglecompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getCompanyBySellerId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyBySellerId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sellercompany = action.payload.sellercompany;
      })
      .addCase(getCompanyBySellerId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        state.company = state.company?.filter(
          (c) => c.company_id !== action.payload.companyId
        );
      })
      .addCase(deleteCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
  },
});

export const { clearErrors, clearMessage } = companySlice.actions;

export default companySlice.reducer;
