// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const placeOrder = createAsyncThunk(
  "user/placeOrder",
  async ( values, thunkAPI) => {
    try {
      const response = await fetch("https://api.ownbasket.shop/api/v1/order/place", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
       

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const getOrderById = createAsyncThunk(
  "staff/getOrderById",
  async (order_id, thunkAPI) => {
    try {
      const response = await fetch(`https://api.ownbasket.shop/api/v1/order/user/${order_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getSellerOrderById = createAsyncThunk(
  "staff/getSellerOrderById",
  async (seller_id, thunkAPI) => {
    try {
      console.log(seller_id);
      const response = await fetch(`https://api.ownbasket.shop/api/v1/order/sellerorder/${seller_id}`);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


export const getOrder = createAsyncThunk(
    "staff/getOrder",
    async (user_id, thunkAPI) => {
      try {
        const response = await fetch(`https://api.ownbasket.shop/api/v1/order/buyer/${user_id}`);
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        console.log(data);
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
export const getAdminOrder = createAsyncThunk(
    "staff/getAdminOrder",
    async (_, thunkAPI) => {
      try {
        const response = await fetch(`https://api.ownbasket.shop/api/v1/order/adminorder`);
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message);
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        // Handle error
        return thunkAPI.rejectWithValue({ error: error.message });
      }
    }
  );
export const deleteOrder = createAsyncThunk(
  "staff/deleteOrder",
  async (order_id, thunkAPI) => {
    try {
      // Your asynchronous logic to delete student here
      const response = await fetch(`https://api.ownbasket.shop/api/v1/order/${order_id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }
      const data = await response.json();
      return { orderId: order_id, message: data.message };
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const updateOrder = createAsyncThunk(
  "student/updateOrder",
  async ({ order_id, updatedData }, thunkAPI) => {
    try {
      // Your asynchronous logic to update student here
      const response = await fetch(`https://api.ownbasket.shop/api/v1/order/update/${order_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      // Handle error
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialState = {
  order: null,
  sellerorder: null,
  orderbyid:null,
  adminorder:null,
  loading: false,
  error: null,
  message: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(placeOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 
      .addCase(getOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.order = action.payload.order;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })


      .addCase(getSellerOrderById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellerOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sellerorder = action.payload.sellerorder;
      })
      .addCase(getSellerOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getAdminOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.adminorder = action.payload.adminorder;
      })
      .addCase(getAdminOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(getOrderById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.orderbyid = action.payload.orderbyid;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
      .addCase(deleteOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
        state.cart = state.cart.filter(
          (c) => c.order_id !== action.payload.orderId
        );
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      }) 
      .addCase(updateOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      })
    
  },
});

export const { clearErrors, clearMessage } = orderSlice.actions;

export default orderSlice.reducer;
