import React from "react";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark, FaEye } from "react-icons/fa6";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { Confirmation } from "../../BaseFiles/Confirmation";
import {
  clearErrors,
  clearMessage,
  getCompany,
  getCompanyBySellerId,
  deleteCompany,
  updateCompany,
} from "../../redux/companySlice";
import { useDispatch, useSelector } from "react-redux";
import { BsPencilSquare } from "react-icons/bs";
import { FaRegTrashAlt, FaCheck, FaSearch } from "react-icons/fa";
import EditModal from "./EditModal";
const Company = (props) => {
  const currentUrl = window.location.href;
  const [editMode, setEditMode] = useState(false);
  const [editableCompany, seteditableCompany] = useState(null);
  const [modalopen, setModalopen] = useState(false);
  const [deleteID, setdeleteID] = useState();
  const { loading, error, message, company, sellercompany } = useSelector(
    (state) => state.company
  );
  const { user } = useSelector((state) => state.user);
  const [allcompany, setAllcompany] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const [modelOpen, setModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  function isClose() {
    setModalopen(false);
  }
  function handleDelete(id) {
    setdeleteID(id);
    if (deleteID) {
      setModalopen(true);
    }
  }
  let addUsersValues = {
    company_name: "",
    gst: "",
    seller_name: "",
    category: "",
  };

  useEffect(() => {
    if (user?.role == "seller") {
      dispatch(getCompanyBySellerId(user?.user_id));
    } else if (user?.role == "admin") {
      setAllcompany(company);
      dispatch(getCompany());
    }

    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message, user?.role]);

  const thds = [
    "Company id",
    "logo",
    "seller id",
    "Company name",
    "gst number",
    "category",
    "status",
  ];

  const handleEditComapny = (data) => {
    setCompanyData(data);
    setModalOpen(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    seteditableCompany(null);
  };

  const formik = useFormik({
    initialValues: addUsersValues,
    onSubmit: (values) => {
      const filteredData = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) => value !== "" && value !== null
        )
      );

      if (Object.keys(filteredData).length > 0) {
        dispatch(
          updateCompany({
            company_id: editableCompany.company_id,
            updatedData: filteredData,
          })
        );
      } else {
        alert("No Changes were made");
      }

      setEditMode(false);
      seteditableCompany(null);
    },
  });

  const handleSearch = (e) => {
    setAllcompany(
      company?.filter((c) => c.company_name?.includes(e.target.value))
    );
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Company
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the company in your account including their Id,
              logo,name, gst, category and status .
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Link
              to="/add/company"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Compnay
            </Link>
          </div>
        </div>
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div
                className={`flex bg-white justify-center ${
                  loading ? "h-[560px] items-center" : "h-full"
                }`}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {sellercompany?.map((ac, index) => (
                      <div
                        key={index}
                        className="w-full max-w-sm bg-white p-4 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
                      >
                        <div className="flex flex-col items-center pb-10">
                          <img
                            className="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={`/companylogo/${ac?.avatar}`}
                            alt="Bonnie image"
                          />
                          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {ac?.company_name}
                          </h5>
                          <span className="text-sm item-left text-gray-500 dark:text-gray-400">
                            {ac?.gst}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {ac?.category}
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {ac?.status}
                          </span>
                          <div className="flex mt-4 md:mt-6">
                            <button
                              onClick={() => handleEditComapny(ac)}
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => handleDelete(ac?.company_id)}
                              className="py-2 px-4 ms-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {companyData && (
        <EditModal
          open={modelOpen}
          setOpen={setModalOpen}
          companyData={companyData}
        />
      )}

      {modalopen && (
        <Confirmation
          isClose={isClose}
          deletefunction={deleteCompany}
          id={deleteID}
        />
      )}
    </>
  );
};

export default Company;
