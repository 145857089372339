import ProductCard from "../Home/ProductCard";
import React from 'react';
import Loader from "../../BaseFiles/Loader";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  clearErrors,
  clearMessage,
  getProductbycategory,
} from "../../redux/productSlice";
import { useDispatch, useSelector } from "react-redux";
export default function ProductList() {
    const {category,gender,subcategory} = useParams()
    const dispatch = useDispatch();
    const [allproductbycategory, setAllproductbycategory] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const { loading, error, message, productbycategory } = useSelector(
        (state) => state.product
      );

      useEffect(() => {
        setAllproductbycategory(productbycategory);
        dispatch(getProductbycategory({category,gender,subcategory} ));
        if (error) {
          const errorInterval = setInterval(() => {
            dispatch(clearErrors());
          }, 3000);
          return () => clearInterval(errorInterval);
        }
        if (message) {
          const messageInterval = setInterval(() => {
            dispatch(clearMessage());
          }, 3000);
          return () => clearInterval(messageInterval);
        }
      }, [dispatch, error, message,category,gender,subcategory]);

      const handleSearch = (e) => {
        setAllproductbycategory(productbycategory?.filter((p) => p.title?.includes(e.target.value)));
        setSearchQuery(e.target.value);
      };
  
 
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    // Calculate the items to be displayed on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = productbycategory?.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total number of pages
    const totalPages = Math.ceil(productbycategory?.length / itemsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <section className="py-0 bg-white sm:py-0 lg:py-0">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-2 gap-6 lg:mt-16 lg:gap-4 lg:grid-cols-4">
                    {currentItems?.map((linkk, index) => (
                        <ProductCard key={index} productwithcategory={linkk} />
                    ))}
                </div>
                <div className="flex justify-center mt-8 items-center">
                    <button 
                        onClick={handlePreviousPage} 
                        disabled={currentPage === 1}
                        className="px-4 py-2 mx-2 text-white bg-blue-500 rounded disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <span className="mx-2">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button 
                        onClick={handleNextPage} 
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 mx-2 text-white bg-blue-500 rounded disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </div>
        </section>
    );
}