import { useState, useEffect } from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Radio,
  RadioGroup,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import { getSingleProduct } from "../../redux/productSlice";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import Spinner from "../../BaseFiles/Spinner";
import { addToCart, clearErrors, clearMessage } from "../../redux/cartSlice";
import { StarIcon } from "@heroicons/react/20/solid";
import { HeartIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductDetail() {
  const [imagesArray, setImagesArray] = useState([]);
  const [colorArray, setColorArray] = useState([]);
  const [sizeArray, setSizeArray] = useState([]);
  const [LoginMessage, setLoginMessage] = useState();

  const { pro_id } = useParams();
  const dispatch = useDispatch();
  const { singleproduct } = useSelector((state) => state.product);
  const { loading, error, message } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);

  function handlecart() {
    if (user?.user_id) {
      dispatch(addToCart({ user_id: user.user_id, product_id: singleproduct.product_id, quantity: 1 }));
    } else {
      setLoginMessage("login first");
    }
  }

  useEffect(() => {
    if (pro_id) {
      dispatch(getSingleProduct(pro_id));
    }
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
    if (LoginMessage) {
      const loginMessageInterval = setInterval(() => {
        setLoginMessage(null);
      }, 3000);
      return () => clearInterval(loginMessageInterval);
    }
  }, [dispatch, error, message, LoginMessage, pro_id]);

  useEffect(() => {
    if (singleproduct && Object.keys(singleproduct).length > 0) {
      setImagesArray(
        Object.keys(singleproduct)
          .filter((key) => key.startsWith("image"))
          .map((key) => singleproduct[key])
          .filter((img) => img !== null)
      );

      setColorArray(
        Object.keys(singleproduct)
          .filter((key) => key.startsWith("color"))
          .map((key) => singleproduct[key])
          .filter((color) => color !== null)
      );

      setSizeArray(
        Object.keys(singleproduct)
          .filter((key) => key.startsWith("size"))
          .map((key) => singleproduct[key])
          .filter((size) => size !== null)
      );
    }
  }, [singleproduct]);

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {message && <SuccessAlert message={message} />}
        {error && <ErrorAlert error={error} />}
        {LoginMessage && <ErrorAlert error={LoginMessage} />}
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <TabGroup className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
              <TabList className="grid grid-cols-4 gap-6">
                {imagesArray?.map((image) => (
                  <Tab
                    key={image}
                    className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    <span className="absolute inset-0 overflow-hidden rounded-md">
                      <img
                        alt=""
                        src={`/proimage/${image}`}
                        className="h-full w-full object-cover object-center"
                      />
                    </span>
                    <span
                      aria-hidden="true"
                      className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
                    />
                  </Tab>
                ))}
              </TabList>
            </div>

            <TabPanels className="aspect-h-1 aspect-w-1 w-full">
              {imagesArray?.map((image, index) => (
                <TabPanel key={index}>
                  <img
                    src={`/proimage/${image}`}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </TabGroup>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              {singleproduct?.title}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                {singleproduct?.price} Rs
              </p>
            </div>

            {/* Reviews */}
            <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      aria-hidden="true"
                      className={classNames(
                        singleproduct?.rating > rating
                          ? "text-indigo-500"
                          : "text-gray-300",
                        "h-5 w-5 flex-shrink-0"
                      )}
                    />
                  ))}
                </div>
                <p className="sr-only">
                  {singleproduct?.rating} out of 5 stars
                </p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              <div
                dangerouslySetInnerHTML={{
                  __html: singleproduct?.description,
                }}
                className="space-y-6 text-base text-gray-700"
              />
            </div>

            {/* Colors */}
            <div>
              <h3 className="text-sm font-medium text-gray-600">Color</h3>

              <fieldset aria-label="Choose a color" className="mt-2">
                <RadioGroup className="flex items-center space-x-3">
                  {colorArray?.map((color, index) => (
                    <Radio
                      key={index}
                      value={color}
                      aria-label={color}
                      className={classNames(
                        color.selectedColor,
                        "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-2 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          color.bgColor,
                          "h-8 w-8 rounded-full border border-black border-opacity-10"
                        )}
                      />
                    </Radio>
                  ))}
                </RadioGroup>
              </fieldset>
            </div>

            <div className="mt-10 flex">
              <button
                onClick={() => handlecart(singleproduct)}
                className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
              >
                {loading ? <Spinner /> : "Add to Cart"}
              </button>

              <button
                type="button"
                className="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
              >
                <HeartIcon
                  aria-hidden="true"
                  className="h-6 w-6 flex-shrink-0"
                />
                <span className="sr-only">Add to favorites</span>
              </button>
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>

              <div className="divide-y divide-gray-200 border-t">
                <Disclosure as="div">
                  <h3>
                    <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                      <span className="text-sm font-medium text-gray-900 group-data-[open]:text-indigo-600">
                        Colors
                      </span>
                      <span className="ml-6 flex items-center">
                        <PlusIcon
                          aria-hidden="true"
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                        />
                        <MinusIcon
                          aria-hidden="true"
                          className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="prose prose-sm pb-6">
                    <ul role="list">
                      {colorArray?.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>

              <div className="divide-y divide-gray-200 border-t">
                <Disclosure as="div">
                  <h3>
                    <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                      <span className="text-sm font-medium text-gray-900 group-data-[open]:text-indigo-600">
                        Sizes
                      </span>
                      <span className="ml-6 flex items-center">
                        <PlusIcon
                          aria-hidden="true"
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                        />
                        <MinusIcon
                          aria-hidden="true"
                          className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="prose prose-sm pb-6">
                    <ul role="list">
                      {sizeArray?.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>

              <div className="divide-y divide-gray-200 border-t">
                <Disclosure as="div">
                  <h3>
                    <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                      <span className="text-sm font-medium text-gray-900 group-data-[open]:text-indigo-600">
                        Shipping
                      </span>
                      <span className="ml-6 flex items-center">
                        <PlusIcon
                          aria-hidden="true"
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                        />
                        <MinusIcon
                          aria-hidden="true"
                          className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="prose prose-sm pb-6">
                    <ul role="list">
                      <li> Free shipping on orders over $300</li>
                      <li> International shipping available</li>
                      <li> Expedited shipping options</li>
                      <li> Signature required upon delivery</li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>

              <div className="divide-y divide-gray-200 border-t">
                <Disclosure as="div">
                  <h3>
                    <DisclosureButton className="group relative flex w-full items-center justify-between py-6 text-left">
                      <span className="text-sm font-medium text-gray-900 group-data-[open]:text-indigo-600">
                        Return
                      </span>
                      <span className="ml-6 flex items-center">
                        <PlusIcon
                          aria-hidden="true"
                          className="block h-6 w-6 text-gray-400 group-hover:text-gray-500 group-data-[open]:hidden"
                        />
                        <MinusIcon
                          aria-hidden="true"
                          className="hidden h-6 w-6 text-indigo-400 group-hover:text-indigo-500 group-data-[open]:block"
                        />
                      </span>
                    </DisclosureButton>
                  </h3>
                  <DisclosurePanel className="prose prose-sm pb-6">
                    <ul role="list">
                      <li> Easy return requests</li>
                      <li> Pre-paid shipping label included</li>
                      <li> 10% restocking fee for returns</li>
                      <li> 60 day return window</li>
                    </ul>
                  </DisclosurePanel>
                </Disclosure>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
