import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import Loader from "../../BaseFiles/Loader";
import { FaAngleDown, FaArrowsRotate, FaXmark } from "react-icons/fa6";
import SuccessAlert from "../../BaseFiles/SuccessAlert";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/20/solid";
import Spinner from "../../BaseFiles/Spinner";
import {
  companyRegistration,
  clearErrors,
  clearMessage,
} from "../../redux/companySlice";
import ErrorAlert from "../../BaseFiles/ErrorAlert";
import { logoutUser } from "../../redux/userSlice";
import EditModal from "./EditModal";

export default function BecomeSeller() {
  const {  user } = useSelector((state) => state.user);
  const {loading, message, error } = useSelector((state) => state.company);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    seller_id: user.user_id,
    company_name: "",
    gst: "",
    email: "",
    phone: "",
    category: "",
    file: null,
  };
  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    category: Yup.string().required("Category is required"),
    file: Yup.mixed()
      .required("File is required")
      .test("fileSize", "File too large", value => value && value.size <= 2 * 1024 * 1024) // 1 MB limit
      .test("fileType", "Unsupported file format", value => value && ["image/jpeg", "image/png", "application/pdf"].includes(value.type)), // Allowed file types
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('company_name', values.company_name);
      formData.append('seller_id', values.seller_id);
      formData.append('gst', values.gst);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
      formData.append('category', values.category);
      if (values.file) {
        formData.append('file', values.file);
      }

      dispatch(companyRegistration(formData));
      console.log(values);
    },
  });
  console.log(formik.errors);
  const handleFileChange = (event) => {
    formik.setFieldValue("file", event.currentTarget.files[0]);
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);

      return () => clearTimeout(timer);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
        formik.resetForm()
      }, 3000);

      return () => clearInterval(messageInterval);
    }
  }, [error, dispatch, navigate, message]);

  return (
    <>
    {message && <SuccessAlert message={message} />}
    {error && <ErrorAlert error={error} />}
    <div className="w-full flex ">
      

      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit} className="m-auto shadow-lg w-3/4 p-5 my-5">
          <div className="space-y-12 sm:space-y-16 mx-auto">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Add Company
              </h2>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                This information will be verify so be careful what you share.
              </p>

              <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="company_name"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Comapny Name
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        Name
                      </span>
                      <input
                        id="company_name"
                        type="text"
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.company_name &&
                      formik.errors.company_name && (
                        <p className="text-red-500 text-xs mt-1">
                          {formik.errors.company_name}
                        </p>
                      )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="gst"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Gst Number
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        Gst
                      </span>
                      <input
                        id="gst"
                        type="text"
                        value={formik.values.gst}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.gst && formik.errors.gst && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.gst}
                      </p>
                    )}
                  </div>
                </div>


                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    category
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                      Category
                      </span>
                      <input
                        id="category"
                        type="text"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.category && formik.errors.category && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.category}
                      </p>
                    )}
                  </div>
                </div>
                


                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Email
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        email
                      </span>
                      <input
                        id="email"
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="Phone"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Phone
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        +91
                      </span>
                      <input
                        id="phone"
                        type="text"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.touched.phone && formik.errors.phone && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.phone}
                      </p>
                    )}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label
                    htmlFor="file"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Company Logo
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                      <input
                        id="file"
                        name="file"
                        type="file"
                        hidden
                        accept="file/png, file/jpeg"
                        onChange={handleFileChange}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {formik.values.file
                      ? formik.values.file.name.slice(0, 23)
                      : "Choose an file (PNG, JPG, GIF up to 2MB)"}
                  </div>
                  {formik.touched.file && formik.errors.file && (
                      <p className="text-red-500 text-xs mt-1">
                        {formik.errors.file}
                      </p>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 ml-96 flex items-center justify-center gap-x-6">
            <button
              onClick={formik.resetForm}
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
             {loading ? <Spinner /> : "Save"}
         
            </button>
          </div>
        </form>
      )}
    </div>
    </>
  );
}
