import React, { useState } from "react";

const HeroSection = () => {
  return (
    <>
      <div className="relative bg-white lg:pl-32 pl-4 pt-4 z-10">
        <div className="container z-10">
          <div className="-mx-4 flex flex-wrap z-10">
            <div className="w-full px-4 lg:w-5/12">
              <div className="hero-content">
                <h1 className="mb-5 text-4xl font-bold !leading-[1.208] text-dark dark:text-white sm:text-[42px] lg:text-[40px] xl:text-5xl">
                Your Ultimate Multi-Vendor Marketplace
                </h1>
                <p className="mb-8 max-w-[480px] text-base text-body-color dark:text-dark-6">
                Find a Wide Variety of Products including trendy fashion,
                 the latest tech, stylish home items, premium beauty
                  products, and fresh groceries - all in one place.
                  <br/>
                  <spam className="text-[#3758F9]">New Customer? Get 10% Off Your First Order!</spam>
                </p>
                <ul className="flex flex-wrap items-center">
                  <li>
                   <button type="button" class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-700 hover:bg-gray-100 hover:text-blue-700 focus:z-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"> Get Started</button>
                  </li>
                  <li>
                    <a
                      href="/#"
                      className="inline-flex items-center justify-center px-5 py-3 text-center text-base font-medium text-[#464646] hover:text-primary dark:text-white"
                    >
                      <span className="mr-2">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12.6152" r="12" fill="#3758F9" />
                          <rect
                            x="7.99893"
                            y="14.979"
                            width="8.18182"
                            height="1.63636"
                            fill="white"
                          />
                          <rect
                            x="11.2717"
                            y="7.61523"
                            width="1.63636"
                            height="4.09091"
                            fill="white"
                          />
                          <path
                            d="M12.0898 14.1606L14.9241 11.0925H9.25557L12.0898 14.1606Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      Download App
                    </a>
                  </li>
                </ul>
                <div className="clients pt-12">
                 
                  <div className="flex items-center space-x-4">
                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/ayroui.svg"
                    />

                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/graygrids.svg"
                    />

                    <SingleImage
                      href="#"
                      imgSrc="https://cdn.tailgrids.com/2.0/image/assets/images/brands/uideck.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 lg:block lg:w-1/12"></div>
            <div className="hidden md:block  w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                   
        <div className="flex bg-white h-[500px]">
          <div className="w-1/5 mx-4 px-2 py-32 bg-white">
            <img
              className="mx-6 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/610V0M4gfYL._AC_UL480_FMwebp_QL65_.jpg"
            />
          </div>
          <div className="w-1/5 px-2  py-4 mx-4 bg-white">
            <img
              className="mx-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/71QSPoUBEeL._AC_UL480_FMwebp_QL65_.jpg"
            />
            <img
              className="mx-2 mt-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/71437j57EeL._AC_UL480_FMwebp_QL65_.jpg"
            />
          </div>
          <div className="w-1/5 px-2  pt-16 mx-4 bg-white">
            <img
              className="mx-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/81FsiUBcq1L._AC_UL480_QL65_.jpg"
            />
            <img
              className="mx-2 mt-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/41ux2IY5ErL._SX300_SY300_QL70_FMwebp_.jpg"
            />
          </div>
          <div className="w-1/5 px-2 py-4 mx-4 bg-white">
            <img
              className="mx-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/51QdOSrkrtL._SY300_SX300_QL70_FMwebp_.jpg"
            />
            <img
              className="mx-2 mt-2 bg-green-400 rounded-2xl shadow-2xl h-[200px]"
              src="https://m.media-amazon.com/images/I/51htgA1eOrL._SX300_SY300_QL70_FMwebp_.jpg"
            />
          </div>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;

const SingleImage = ({ href, imgSrc }) => {
  return (
    <>
      <a href={href} className="flex w-full items-center justify-center">
        <img src={imgSrc} alt="brand image" className="h-10 w-full" />
      </a>
    </>
  );
};


